.media-wrapper {
  min-height: 100px;
}

.media {
  width: 100%;
  background: #fff;
  border-radius: 15px;
}

.messages-header,
.media-header {
  width: 100%;
  height: fit-content;
}

.media-header h3 {
  padding: 5px 0 0 16px;
  font-weight: 500;
  color: #4c4c4c;
}

.media-content-wrapper {
  overflow: auto;
  display: flex;
  width: 100%;
}

.media-content {
  display: flex;
  flex-wrap: nowrap;
  gap: 14px;
  align-items: center;
  justify-content: center;

  padding: 7px 0;
}

.empty-media-item {
  cursor: pointer;

  position: relative;

  overflow: hidden;

  width: 60px;
  height: 70px;

  border-radius: 10px;
}
