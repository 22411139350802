.options {
  clear: both;
  padding: 0 15px;
}

.options-title {
  display: block;

  margin-left: 15px;
  padding-bottom: 1em;

  font-size: 12px;
  font-weight: 400;
  color: gray;
}
