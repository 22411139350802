.container {
  position: absolute;
  top: 0;
  left: 76px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: calc(50% - 38px);
  height: 100vh;

  background-color: rgb(220 220 220 / 75%);

  transition: none;
}
