.pane {
  padding: 10px;
}

.title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.selected {
  color: white;
  background-color: #08e !important;
}

.label {
  display: block;
  min-width: 14px;
  min-height: 14px;
  border-radius: 6px;
}

.label-red {
  background-color: #ff7373;
}

.label-orange {
  background-color: #ffa854;
}

.label-yellow {
  background-color: #fadd00;
}

.label-green {
  background-color: #5be954;
}

.label-blue {
  background-color: #3fbfff;
}

.label-purple {
  background-color: #ce89ff;
}

.label-gray {
  background-color: #aaa;
}
