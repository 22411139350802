.input-time {
  display: block;

  width: 100%;
  margin: 0 auto;

  font-size: 20px;
  color: #444;
}

.bar {
  position: relative;
  display: block;
  border-bottom: 0.0625rem solid #999;
}

.bar::before {
  content: '';

  position: absolute;
  z-index: 2;
  bottom: -0.0625rem;
  left: 50%;

  width: 0;
  height: 0.125rem;

  background: #08e;

  transition: left 0.28s ease, width 0.28s ease;
}

.bar.invalid::before {
  background: #d9534f;
}

.input-time:focus ~ .bar::before {
  left: 0;
  width: 100%;
}

.error-msg {
  font-size: 12px;
  color: #ff5252;
}

.input-time-wrapper input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}
