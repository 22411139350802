.lucky-broadcast {
  margin-top: 3em;
  padding-top: 3em;
  border-top: 1px solid rgb(0 0 0 / 15%);
}

.lucky-broadcast h3 {
  font-size: 2.5vmax;
  font-weight: 200;
  color: gray;
  text-align: center;
}
