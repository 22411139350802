.statistics {
  display: flex;
  flex: 1;
}

.statistics_left {
  justify-content: flex-start;
}

.statistics_right {
  justify-content: flex-end;
}

.info {
  position: sticky;
  z-index: 1;
  top: 5px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  min-width: 300px;
  margin: 8px 0 4px;
  padding: 4px 12px;

  font-size: 12px;

  background: #fff;
  border: 1px solid #eee;
  border-radius: 50px;
}
