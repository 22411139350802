/* .skeleton-wrapper {
    background-color: #eee;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0));
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px +100%) 0;
    }
} */

.skeleton-wrapper {
  position: relative;

  overflow: hidden;
  display: inline-block;

  height: 1em;

  background-color: #dddbdd;
}

.skeleton-wrapper::after {
  content: '';

  position: absolute;
  inset: 0;
  transform: translateX(-100%);

  /* background-image: linear-gradient( 90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0)); */

  /* background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0)); */
  background-image: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    rgb(255 255 255 / 20%) 20%,
    rgb(255 255 255 / 50%) 60%,
    rgb(255 255 255 / 0%)
  );

  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
