.bs__order {
    /* margin-top: -17px; */
    flex-shrink: 0;

    margin-right: 4px;
    padding-bottom: 4px;

    font-size: 15px;
    color: #808080;
}

.bs__order span {
    padding-bottom: 4px;
    border-bottom: 1px solid;
}


.options {
    display: block;
}