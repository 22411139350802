.work-content {
  position: relative;
  /* Для высоты body registaration */
  display: flex;
  flex-direction: column;
  /* ======== */
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  height: 100%;
  padding-top: 40px;

  background-color: rgb(255 255 255 / 50%);
}