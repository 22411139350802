.error-screen {
  position: fixed;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: #fff;
}

.error-block {
  max-width: 785px;
  padding: 20px;
  color: #808080;
}

.error-message {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.error-message--small {
  margin: 20px 0 40px;
  font-size: 16px;
  line-height: 19px;
}
