.count-all {
  margin: 0 15px;
  margin-top: 1em;
  padding-top: 0.5em;

  font-weight: bold;
  text-align: right;

  border-top: 1px solid silver;
}

.count-all--reverse {
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border: none;
  border-bottom: 1px solid silver;
}

.count-all-title {
  font-weight: normal;
  color: gray;
}
