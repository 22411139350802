.sub-controls {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 1.14rem;
}

.volume {
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
