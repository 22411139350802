.picker-wrapper {
  position: absolute;
  transform: translateX(50%) translateY(-50%);
  transition: all 0.3s;
}

.picker-footer {
  padding: 0 32px !important;
}

.input-time {
  display: block;

  width: 100%;
  margin: 0 auto;

  font-size: 20px;
  color: #444;

  border-bottom: 1px solid;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 !important;
}
