.main-chat-header {
  position: absolute;
  z-index: 3;
  top: 42px;

  width: 100%;
  padding: 8px 20px;

  background: #fff;
  border-bottom: 2px solid #e5e5e5;
}

.main-chat-header_user-data {
  display: flex;
  align-items: center;
}

.main-chat-header_user-data_info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
}

.main-chat-header_user-data_info-name {
  cursor: pointer;

  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: bold;
  color: #2b2c33;

  transition: all 0.4s linear;
}

.main-chat-header_user-data_info-contacts {
  cursor: pointer;
  padding: 2px 0;
  color: #858585;
}

.main-chat-header_user-data_info-contacts:hover {
  text-decoration: underline;
}

.location {
  align-self: center;

  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  color: #858585;
}

.name {
  margin-right: 10px;
}

.main-chat-header_user-data_info-channels .sender-number {
  align-self: flex-start;
  font-size: 16px;
  color: #8a8d91;
}

.main-chat-header_user-data_info-channels .sender-number a {
  color: inherit;
}
