.slider {
  width: 100%;
  height: 3px;

  appearance: none;
  opacity: 0.7;
  background: #d3d3d3;
  border-radius: 5px;
  outline: none;

  transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  cursor: pointer;

  width: 11px;
  height: 11px;

  appearance: none;
  background: dodgerblue;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  cursor: pointer;

  width: 11px;
  height: 11px;

  background: dodgerblue;
  border-radius: 50%;
}

.title {
  display: flex;
  justify-content: space-between;
}

.slidecontainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
