.action-export {
  float: right;
  display: flex;
  margin-left: 20px;
  font-size: 0.85em;
}

.char-counter {
  align-self: center;

  margin-right: 20px;

  color: #08e;
  text-align: center;

  opacity: 0.75;
}

.char-counter.warning {
  color: rgb(255 51 51);
}
