.session-module {
  position: relative;

  width: 100%;
  height: 100%;
  padding-top: 40px;

  /* overflow-x: hidden; */

  /* overflow-y: auto; */
  background-color: rgb(255 255 255 / 50%);
}

.scroll-body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
