.select-all-btn {
  text-align: left;
}

.select-all-text {
  user-select: none;
  position: relative;
  top: 4px;
  margin-left: 45px;
  font-size: 15px;
}

.pure-material-checkbox {
  position: relative;
  z-index: 0;
  display: block;
  height: 45px;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

/* Input */

.pure-material-checkbox>input {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -2px;
  transform: scale(1);
  display: block;
  width: 36px;
  height: 36px;
  margin: 0;
  appearance: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */

.pure-material-checkbox>span {
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

/* Box */

.pure-material-checkbox>span::before {
  content: '';
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin: 3px 11px 3px 1px;
  vertical-align: top;
  border: solid 2px;
  /* Safari */
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */

.pure-material-checkbox>span::after {
  content: '';
  position: absolute;
  top: 9px !important;
  left: 6px !important;
  transform: translate(3px, 4px) rotate(-45deg);
  display: block;
  width: 12px;
  height: 6px;
  border: solid 2px transparent;
  border-top: none;
  border-right: none;
}

/* Checked, Indeterminate */

.pure-material-checkbox>input:checked,
.pure-material-checkbox>input:indeterminate {
  background-color: rgb(33, 150, 243);
}

.pure-material-checkbox>input:checked+span::before,
.pure-material-checkbox>input:indeterminate+span::before {
  background-color: rgb(33, 150, 243);
  border-color: rgb(33, 150, 243);
}

.pure-material-checkbox>input:checked+span::after,
.pure-material-checkbox>input:indeterminate+span::after {
  border-color: rgb(255, 255, 255);
}

.pure-material-checkbox>input:indeterminate+span::after {
  transform: translate(4px, 3px);
  border-left: none;
}

/* Hover, Focus */

.pure-material-checkbox:hover>input {
  opacity: 0.04;
}

.pure-material-checkbox>input:focus {
  opacity: 0.12;
}

.pure-material-checkbox>input:active {
  transform: scale(0);
  opacity: 1;
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox>input:disabled {
  opacity: 0;
}

.pure-material-checkbox:hover>input:focus {
  opacity: 0.16;
}

/* Active */

.pure-material-checkbox>input:active+span::before {
  border-color: rgb(33, 150, 243);
}

.pure-material-checkbox>input:disabled+span::before {
  border-color: currentcolor;
}

.pure-material-checkbox>input:checked:active+span::before {
  background-color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
}

/* Disabled */

.pure-material-checkbox>input:disabled+span {
  cursor: initial;
  color: rgba(0, 0, 0, 0.38);
}

.pure-material-checkbox>input:checked:disabled+span::before,
.pure-material-checkbox>input:indeterminate:disabled+span::before {
  background-color: currentcolor;
  border-color: transparent;
}