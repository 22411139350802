.snack_elem {
  position: absolute;
  bottom: 0;
  left: -100%;

  display: inline-flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;

  min-width: 288px;
  max-width: 400px;
  margin: 0;
  margin-bottom: 10px;
  padding: 6px 24px;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  letter-spacing: 0.01071em;

  background-color: rgb(49 49 49);
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);

  /* animation: leftSlide 0.3s ease 0s 1 forwards; */

  /* overflow: hidden;
  transform: translateX(-2000px);
  max-height: 0;
  animation: heightUp 10s ease-out 1s 1 forwards, backInLeft 1s ease-out 0s 1 forwards; */

  /* , backInLeft 1s ease-out 1s 1 forwards; */

  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.snack-content {
  display: flex;
  align-items: center;

  max-width: 100%;
  padding: 8px 0;
  padding-right: 20px;
}

.danger {
  background-color: #de4646;
}

.success {
  background-color: #4eb152;
}

.info {
  background-color: #202124;
}

.warning {
  background-color: #ffa000;
}

@keyframes backInLeft {
  0% {
    transform: translateX(-2000px);
    opacity: 0.7;
  }

  80% {
    opacity: 0.7;
  }

  100% {
    /* transform: scale(1); */
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes heightUp {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 1000px;
  }
}

@keyframes leftSlide {
  0% {
    left: -100px;
  }

  100% {
    left: 24px;
  }
}

.backOutLeft {
  left: 24px !important;
  animation: backOutLeft 0.7s ease 0s 1 forwards;
}

@keyframes backOutLeft {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: translateX(0) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }
}
