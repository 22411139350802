.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo span {
  display: block;

  width: 64px;
  height: 64px;
  margin: auto;

  background-color: #00cfff;
  background-image: url('http://iactive.pro/images/logo130x62_white.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  border-radius: 40px;
}

.logoImage {
  display: block;
  width: 128px;
  height: 128px;
}

.welcome-header {
  margin: 0.1em 0 0.67em;

  font-size: 3vmax;
  font-weight: 200;
  color: gray;
  text-align: center;
}

.welcome-header span {
  display: block;
  font-size: 0.5em;
  font-weight: 300;
  opacity: 0.9;
}

.tgray {
  margin: 0 4em;
  color: rgb(0 0 0 / 33%);
  text-align: center;
}
