.delete-card-modal {
  width: 90%;
  max-width: 580px;
  background: #fff;
  border-radius: 4px;
}

.card-header {
  padding: 16px 20px 16px 40px !important;
  border-bottom: 1px solid rgb(205 205 205);
}

.card-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
}
