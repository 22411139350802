.container {
  display: flex;
  align-items: center;
}

.head {
  cursor: pointer;
  flex-shrink: 0;
}

.head-title {
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}

.message {
  overflow: hidden;
  flex-shrink: 1;

  max-height: 18px;
  margin-left: 20px;

  color: #404040;
}

.clickableImage {
  cursor: pointer;
}
