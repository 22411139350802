.main-chat-msg-input {
  will-change: width;

  flex: 1 1 auto;

  box-sizing: border-box;
  width: inherit;
  min-width: 0;
  min-height: 20px;
  margin: 5px 10px;
  padding: 9px 12px 11px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 21px;
  outline: none;
}

.main-chat-msg-input.focusable:focus-within {
  box-shadow: 0 0 0 2px #08c;
}

.main-chat-msg-input-wrapper {
  cursor: text;

  position: relative;

  overflow: hidden;
  display: flex;
  flex: 1;

  padding-right: 0;
}

.main-chat-msg-input-wrapper_placeholder {
  pointer-events: none;
  user-select: none;

  position: absolute;
  z-index: 100;
  z-index: 2;
  top: 0;
  left: 2px;

  font-size: 15px;
  line-height: 20px;

  /* visibility: hidden; */
  color: #999;

  transition: opacity 0.08s linear;
}

.main-chat-msg-input-wrapper_text {
  resize: none;

  position: relative;
  z-index: 1;

  overflow-x: hidden;
  overflow-y: auto;

  box-sizing: border-box;
  width: 100%;

  /* max-height: 100px; */
  min-height: 20px;
  padding: 0;
  padding-right: 2px;

  font-size: 15px;
  font-weight: 400;
  color: #4a4a4a;
  word-wrap: break-word;
  white-space: pre-wrap;

  outline: none;
}

.label {
  cursor: pointer;
  color: #404040;
  opacity: 0.5;
}

.label-focused {
  opacity: 1;
}

.main-chat-msg-input.invalid {
  border-color: #ff2525;
}