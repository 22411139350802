.news-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 8px;
  padding: 8px 20px;
}

.news-card > * {
  grid-column: span 4;
}

.pictureLoader {
  grid-column: span 1;
}

.news-card > div {
  margin-top: 7px !important;
}

.audio-setting {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;

  margin-top: 5px;
}
