.modal-window-image {
  position: fixed;
  z-index: 1001;
  inset: 0;

  background-color: rgb(0 0 0 / 80%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-color: transparent;
  border-style: solid;
  border-width: 15px 15px 50px 50px;
  outline: none;
}

.modal-window-image__desc {
  position: absolute;
  bottom: 1%;
  left: 2%;
  color: #ccc;
}

.modal-window-image__header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  padding: 20px 15px;
}

.modal-window-image__header-info {
  position: relative;
  width: 90%;
  margin-left: 20px;
}

.modal-window-image__header-info-name {
  display: block;

  width: fit-content;

  font-size: 25px;
  line-height: 1.5;
  color: #ccc;
  text-decoration: none;
}

.modal-window-image__header-info-date {
  width: fit-content;
  margin: 0;
  font-size: 18px;
  color: #a1a1a1;
}
