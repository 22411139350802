.wrapper-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-size: 29px;
  color: #353535;

  opacity: 0.4;
}

.span-404 {
  display: block;
  margin-bottom: 18px;
  font-size: 100px;

  /* color: #ccc; */
  color: #000;
}

.container-404 {
  position: relative;
  height: 100%;
}

.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  margin: 0;
}
