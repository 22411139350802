.toolbar {
    padding-right: 25px;
    overflow: hidden;
}

.toolbarFull {
    height: max-content;
}

.toolbarMin {
    height: 44px;
}