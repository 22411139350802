.other-sett {
  position: relative;
}

.save-settings {
  position: absolute;
  top: 16px;
  right: 30px;

  outline: none;
  box-shadow: none;

  transition: box-shadow 0.2s ease-in-out;
}

.sett-assessments p {
  margin: 1em 0;
}

.sett-assessments ul {
  margin: 1em 0;
}

.sett-assessments span {
  color: #848484;
}

.other-sett__h3 {
  margin: 1em 0;
  padding: 1em 0;
  font-weight: 400;
  border-top: 1px solid #ddd;
}

.other-sett__h4 {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  border-bottom: 1px dashed #d6d6d6;
}

.chanelsForAss {
  position: relative;
  width: 100%;
}

.chanelsForAss h4 {
  display: inline-flex;
  justify-content: space-between;

  min-width: 100%;
  max-width: 350px;
  margin-bottom: 0;

  border-bottom: 1px dashed #d6d6d6;
}

.socials-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.rate-cb {
  width: 45%;
  min-width: unset !important;
  max-width: unset !important;
  margin-left: 0 !important;
}
