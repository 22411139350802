.time {
  display: contents;
  align-self: center;
  font-size: 1rem;
}

.past_time {
  /* display: block; */
  display: none;

  /* margin-left: 10px; */
}

.rest_time {
  display: block;
  margin-right: 10px;
}
