.audioSetting {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;

  margin-top: 5px;
}

.blockType {
  display: flex;
  gap: 10px;
  align-items: center;
}

.blockType_label {
  font-size: 16px;
  color: #666;
}

.blockElementText {
  border: 0.5px solid #D0D0D0;
  border-radius: 2px;
  padding: 8px 10px;
  margin: 0
}

.blockElementSelect {
  border-bottom: 1px solid;
  border-radius: 3px 3px 0 0;
  padding-left: 0;
}

.blockElementPicture {
  width: 180px;
  height: 100px;
  background-position: 50% 50%;
  border: 0.5px solid #D0D0D0;
  margin-right: 5px;
}

.blockElementTextField {
  border-color: #D0D0D0;
  padding: 20px 10px 8px;
}