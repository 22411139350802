.filtersContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: 100%;
    padding: 14px 0;
}

.filtersContainer li {
    margin-left: 25px;
}

.btnBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}