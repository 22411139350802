.profile-loading {
  cursor: pointer;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  &__image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
  }

  &__name {
    width: 70px;
    height: 2rem;
    border-radius: 0.25rem;
  }
}
