.card {
  overflow: hidden;

  color: rgb(0 0 0 / 87%);

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
