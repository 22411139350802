.social-conent {
  position: absolute;
  z-index: 101;

  overflow-y: scroll;

  width: 100%;
  height: 80vh;
  padding-bottom: 10px;

  font-size: 0.85em;
  color: #333;
  text-align: center;

  background-color: #fff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 25%);

  transition: height 0.25s ease-in-out;
}

.social-head {
  padding: 15px;
  padding-top: 0;
}

.social-head > h3 {
  margin: 1em 0;
  font-size: 18px;
  font-weight: 300;
}

.social-body {
  padding: 10px;
  padding-right: 28px;
  background-color: #fff;
}

.social-body > div {
  margin-top: 10px;
}

.post-body {
  display: block;

  margin-top: 5px;
  padding-left: 46px;

  font-size: 16px !;
  color: #333 !important;
  text-align: left;
}

.selector-group-time {
  float: right;
  display: block;

  margin-left: 5px;
  padding-top: 4px;

  font-size: 13px !important;
  color: #8e8e8e !important;
}

.channel-mode-btn {
  border: 2px solid #fff;
  border-radius: 50%;
}

.channel-mode-btn:hover {
  border-color: #888;
}

.selected {
  border-color: #888;
}
