.input-group-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: stretch;

  padding-bottom: 0.6em;

  font-size: 10px;
  color: #5a768f;
}

.label {
  pointer-events: none;

  padding-bottom: 0.7em;

  font-size: 1.4em;
  line-height: 1;
  color: #5a768f;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.pre-icon {
  position: absolute;

  margin-top: 0.5em;
  margin-left: 0.6em;

  font-size: 1.7em;
  color: #8e8aff;

  stroke: #655dff;
}

.input {
  width: 100%;
  padding: 0.7em;

  font-family: inherit;
  font-size: 1.4em;
  color: #5a768f;

  background: transparent;
  border: none;
  border: 1px solid #c8dbf0;
  border-radius: 0.6em;
}

.with-pre-icon .input {
  padding-left: 2.5em;
}

.input::placeholder {
  font-size: inherit;

  /* color: var(--greyLight-1); */
  color: var(--grey-light-1);
  letter-spacing: 0.12em;
}

.input:focus {
  border: 1px solid #8e8aff;
  outline: none;
}

.input:hover {
  border: 1px solid #8e8aff;
}

.error-msg {
  display: flex;
  align-items: center;

  margin-top: 0.6em;
  margin-top: 0.4em;

  font-size: 1.2em;
  color: #ff6a96;

  /* opacity: 0; */
}

.error-msg i {
  /* padding: 0 5px; */
  padding-right: 0.4em;
  padding-left: 0.1em;
}
