.radio {
  cursor: pointer;

  display: inline-flex;

  margin: 0;

  text-align: center;

  transition: background-color 0.3s;
}

.radio:hover {
  background-color: #222;
}

.radio_input {
  position: absolute;
  appearance: none;

  /* top: 99999999999; */
}

.radio_label {
  cursor: pointer;

  display: block;

  width: 38px;
  height: 100%;
  padding: 10px 0;

  font-size: 1rem;
  color: #fff;
}

/* .radio:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radio:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
} */

.radio_label:hover {
  color: #cc64b7;
}

.radio_input:checked + .radio_label::before {
  opacity: 1;
}

.radio_input:checked + .radio_label {
  color: #cc64b7;
}
