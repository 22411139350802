/* message.js */
.item {
  user-select: text;

  position: relative;

  display: flex;
  flex-wrap: wrap;

  max-width: 100%;

  /* overflow: hidden; */
  min-height: 70px;
  margin-top: 5px;
  margin-left: 0;
  padding: 10px 10px 10px 7px;

  background-color: white;
  border-bottom: 1px solid #ccc;
  border-left: 3px solid;
  border-radius: 2px;

  transition: height, margin, padding 0.3s;

  /* overflow: hidden; */
}

.item.selected {
  color: #202020;
  background-color: white !important;
  box-shadow: 0 0 3px #08e;
}

.item.minimized {
  /* background-color: rgba(255, 255, 255, 0.5) !important; */
  height: 48px;
  min-height: 48px;
  padding-bottom: 3px;
  box-shadow: none;
}

.item.minimized.selected {
  box-shadow: 0 0 3px rgb(0 136 238 / 50%);
}

/* .item.minimized .icon,
.item.minimized .text,
.item.minimized .media,
.item.minimized .likeBtn,
.item.minimized .dislikeBtn,
.item.minimized .region {
    display: none;
}

.item.minimized .labels {
    opacity: 0.75;
}

.item.minimized .author__source,
.item.minimized .dateTime,
.item.minimized .tag {
    opacity: 0.5;
}

.item.minimized .idMark {
    margin-top: -11px;
} */

/* icon-wrapper */
.icon-wrapper {
  align-self: center;

  /* flex: 0 1 50px; */

  /* border: 1px solid; */
  width: 36px;
}

/* icon */
.icon {
  cursor: pointer;

  display: block;

  width: 36px;
  height: 36px;
  margin: 0 auto;

  opacity: 0.9;

  /* background: #f5f5f5 url("./assets/images/socials/channel-none.png") no-repeat center; */
  background-size: contain;
  border-radius: 3px;
  border-radius: 50%;

  transition: width 0.5s, height 0.5s;
}

.order {
  display: block;
  align-self: center;

  margin-top: 3px;
  padding: 3px 6px;

  line-height: 1;
  color: rgb(82 78 78);
  text-align: center;

  opacity: 0.6;
  border: 1px solid rgb(82 78 78);

  /* background: #0074da; */

  /* color: #fff; */
  border-radius: 10px;
}

.order--active {
  color: #fff;
  opacity: 0.8;
  background: #eaa300;
  border: 1px solid #d49300;
}

.contactInfo>*+* {
  margin-left: 10px;
}

/* region */
.region {
  padding-top: 0;
  font-size: 11px;
  color: #808080;
}

.title {
  font-size: 16px;
  color: #333;
  transition: color 0.2s;

  /* font-style: italic;
  font-size: 12px;
  line-height: 9px; */
}

.title_link:hover {
  color: #08e;
  text-decoration: underline;
}

.text {
  color: #808080;
}

.categories {
  flex: 1;
  flex-wrap: wrap;
}

.author {
  padding: 0 5px;
  font-size: 13px;
}

.author>*+* {
  margin-left: 5px;
}

/* author__source */
.author__source {
  user-select: text;

  font-size: 16px;
  font-weight: bold;
  line-height: 13px;
  color: #808080;
}

.author__source>a {
  font-weight: 500;
  color: rgb(17 15 36 / 50%);
  text-decoration: none;
}

.author__source.minimized {
  opacity: 0.5;
}

.author__name {
  color: #808080;
}

/* datetime */
.dateTime {
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 40px;
  margin-top: 4px;

  font-size: 11px;
  line-height: 1.2;
  color: #808080;

  /* line-height: 9px; */
  text-align: center;
}

.dateTime.minimized {
  opacity: 0.5;
}

/* body */
.body {
  overflow: hidden;

  /* border: 1px solid; */
  flex: 1;
  max-width: 100%;
  padding-left: 10px;
}

.body>* {
  max-width: 100%;
}

/* header */
.header {
  display: flex;
  min-height: 17px;
}

.actions {
  flex-basis: 100px;
}

.content {
  margin-top: 7px;
}

.responses {
  margin: 5px 6px;
}

.footer {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  align-items: flex-end;

  margin-top: 6px;
}

.footer__contact {
  flex-basis: 100%;
}

/* info */

.info {
  flex: 1;
  margin-right: auto;

  /* border: 1px solid; */
}

/* .info>* {
    display: block;
    padding: 5px 0;
} */

/* controls */
.controls {
  display: flex;

  /* border: 1px solid; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  /* overflow: hidden;
    padding-top: 2px */
}

.controls>* {
  display: block;

  /* padding: 5px 0; */
}

.category {
  padding: 3px 6px;

  font-size: 12px;
  line-height: 10px;
  color: #4ea1b7;

  border-radius: 50px;
}

/* channel border */

.chan-facebook {
  border-left-color: #4267b2;
}

.chan-facebookcomments {
  border-left-color: #4267b2;
}

.chan-telegram {
  border-left-color: #44a7d9;
}

.chan-telegrambot {
  border-left-color: #44a7d9;
}

.chan-vkgroup {
  border-left-color: #07f;
}

.chan-vkcomm {
  border-left-color: #07f;
}

.chan-sms {
  border-left-color: #19b89f;
}

.chan-ok,
.chan-okgroup {
  border-left-color: #ff8a19;
}

.chan-viber {
  border-left-color: #9661ab;
}

.chan-livechat {
  border-left-color: #94bd5c;
}

.chan-whatsapp {
  border-left-color: #19e25b;
}

.chan-yt_live {
  border-left-color: #db2833;
}

.chan-smsmoney {
  border-left-color: #19ba9f;
}

.chan-smsMoney {
  border-left-color: #19ba9f;
}

.chan-smsrub {
  border-left-color: #19ba9f;
}

.chan-smsRub {
  border-left-color: #19ba9f;
}

.chan-fbinstagram {
  /* border-left-color: linear-gradient(180deg, rgba(133,69,221,1) 0%, rgba(214,81,173,1) 23%, rgba(252,133,75,1) 67%, rgba(253,212,122,1) 100%); */
  border-left-color: rgb(214 81 173 / 100%);
}

.chan-alisa {
  border-left-color: #7148ff;
}

/* stop animated button */

.hover-active {
  animation: hover-active 1s forwards;
}

.hover-active-done {
  /* width: 100%; */
  transform: translateX(150%);
  opacity: 0;
}

.hover-unactive {
  animation: hover-unactive 1s forwards;
}

.hover-unactive-done {
  transform: translateX(0);
  opacity: 1;

  /* width: 0%; */

  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@keyframes hover-active {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(150%);
    opacity: 0;
  }
}

@keyframes hover-unactive {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}