/* .media-content.selected {
  background-image: url("img/loader.gif");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
} */

.media-video {
  background-image: url('assets/images/icons/media-video@2x.png');
}

.media-sound {
  background-image: url('assets/images/icons/media-sound@2x.png');
}

.media-location {
  background-image: url('assets/images/icons/media-location@2x.png');
}

.media-link {
  background-image: url('assets/images/icons/media-link@2x.png');
}

.media-content {
  overflow: hidden;
  display: flex;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: display 0.25s ease-in-out;
}

.media-content img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.loader {
  display: block;
  width: 40px;
  padding: 10px auto;
}