:root {
  /* :root is a clever way of saying "the outer-most element" */
  --main-color: rgb(0 0 0 / 75%);
  --input-height: 30px;
  --input-font-size: 15px;
  --border-style: solid;

  /* the default */
}

.input-group {
  width: 100%;
  margin-bottom: 5px;
  transition: all 0.3s;
  /* для единообразия с валидацией других титров */
  border: 1px solid #fff;
}

.input-group.minimized {
  width: 20px;
}

.input-group-inner {
  position: relative;
  display: flex;
  margin-bottom: 5px;
}

.input-wrapper {
  width: 100%;
}

.postfix {
  margin-top: 3px;
  margin-left: 3px;
  font-size: 15px;
  color: var(--main-color);
}

.label {
  cursor: pointer;

  position: absolute;

  /* pointer-events: none; */

  /* padding-left: 30px; */
  z-index: 1;
  top: 0.1rem;

  font-size: 1rem;
  font-weight: normal;
  color: #b3b3b3;

  transition: all 0.28s ease;
}

.minimized .label {
  display: none;
}

.has-error .label {
  color: #d9534f !important;
}

.bar {
  position: relative;
  display: block;
  border-bottom: 0.0625rem var(--border-style) #999;
}

.minimized .bar {
  border-bottom: none;
}

.bar::before {
  content: '';

  position: absolute;
  z-index: 2;
  bottom: -0.0625rem;
  left: 50%;

  width: 0;
  height: 0.125rem;

  /* background: #0088EE; */
  background: var(--main-color);

  transition: left 0.28s ease, width 0.28s ease;
}

.has-error .bar::before {
  left: 0;
  width: 100%;
  background: #d9534f;
}

.pre-icon-outer {
  position: absolute;
  bottom: 8px;
}

.pre-icon-inner {
  position: relative;
}

.active-state {
  content: '';

  position: absolute;
  top: -2px;
  right: -2px;

  width: 6px;
  height: 6px;

  background: #ff9898;
  border-radius: 50%;
}

.pre-icon {
  cursor: pointer;
  font-size: 18px;

  /* color: #0088EE; */
  color: var(--main-color);
}

/* .input:focus~.pre-icon {
  color: #0088EE;
} */

.input {
  display: block;

  width: 100%;

  height: var(--input-height);
  padding: 5px 5px 7px;
  padding: 0;

  font-size: var(--input-font-size);
  line-height: 1.4;
  color: transparent;
  color: rgb(0 0 0 / 75%);

  background: none;
  border-color: transparent;
  border-width: 0;
  box-shadow: none;

  transition: all 0.28s ease;
}

.with-pre-icon .input {
  padding-left: 30px;
}

.clearable .input {
  padding-right: 20px;
}

.clear-icon-wrapper {
  cursor: pointer;

  position: absolute;
  right: 4px;
  bottom: 6px;

  display: block;

  font-size: 16px;
  color: #bec3ca;
}

.clear-icon-wrapper:hover {
  color: #83888e;
}

.minimized .clear-icon-wrapper {
  display: none;
}

.input:valid {
  color: var(--main-color);
}

.input:focus {
  color: var(--main-color);
  outline: none;
}

.input.isFilled {
  color: var(--main-color);
}

.input:focus~.label {
  top: -1rem;
  left: 0;

  padding-left: 0;

  font-size: 0.8rem;

  /* color: #337ab7; */
  color: var(--main-color);
}

.input.isFilled~.label {
  top: -1rem;
  left: 0;
  padding-left: 0;
  font-size: 0.8rem;
}

.input:valid:not(.isFilled)~.label {
  top: -1rem;
  left: 0;
  padding-left: 0;
  font-size: 0.8rem;
}

.input:focus~.bar::before {
  left: 0;
  width: 100%;
}

.with-error {
  position: relative;
  padding-bottom: 10px;
}

.error-msg {
  font-size: 12px;
  color: #ff5252;
}

.with-error .error-msg {
  position: absolute;
  /* bottom: 0;
  left: 0; */
}

/* Chrome, Safari, Edge, Opera */

.input-wrapper input::-webkit-outer-spin-button,
.input-wrapper input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */

.input-wrapper input[type='number'],
.hide-number-controls input[type='number'] {
  appearance: textfield;
}


.input-group.invalid {
  border-color: #ff2525;
}