.main-chat-current-date {
  position: absolute;
  z-index: 200;
  z-index: 1;
  top: 65px;
  right: 50%;
  transform: translateX(50%);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 15px;

  font-size: 16px;
  color: #8a8d91;

  opacity: 1;
  background: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);

  transition: all 0.4s ease-in-out;
}
