.add-new-interactive {
  position: absolute;

  width: 350px;
  min-width: 64px;
  margin: 0;
  padding: 5px 10px 10px;

  list-style: none;

  background-color: rgb(255 255 255 / 92%);
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 5px;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 30%);
}
