.message-creator-dialog {
  position: absolute;
  z-index: 10;
  inset: 0;

  width: 100%;

  background-color: rgb(255 255 255 / 25%);
}

.dlg-container {
  position: absolute;
  top: 40px;

  width: 100%;
  padding: 20px;

  background-color: rgb(255 255 255 / 95%);
  box-shadow: 0 20px 20px rgb(0 0 0 / 10%);
}

.channel-mode-btn {
  border: 3px solid #fff;
}

.caption {
  font-size: 0.9em;
  color: gray;
}

.selected {
  opacity: 1;
  box-shadow: 0 1px 8px #08e;
}
