.title {
  padding: 1em 0;
  font-weight: 400;
  border-top: 1px solid #ddd;
}

.input-group {
  display: grid;
  grid-template-columns: 40px auto auto;
  gap: 10px;
  width: 100%;
}

.input-groups__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  justify-items: center;

  padding-top: 0.5rem;
}

.add-btn {
  margin-bottom: 1em;
}
