.newItem {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;

  &__title {
    padding: 8px 10px 0;
    font-size: 20px;
    text-align: center;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    gap: 24px 16px;
    align-items: center;
    align-items: baseline;

    padding: 20px 5px;

    color: #666;
  }

  &__fieldContainer {
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  &__field {
    position: relative;
    grid-column: span 8;
    box-shadow: none !important;

    &_logo {
      grid-column: span 2;
      align-self: center;

      width: 75px;
      height: 75px;
      margin-bottom: 10px;

      & + * {
        align-self: center;
      }

      & + * + * {
        align-self: center;
      }
    }

    &_logo + & {
      grid-column: span 7;
    }

    &_logo + & + & {
      grid-column: span 7;
    }

    input,
    textarea {
      padding: 10px;
      border-radius: 5px;
    }

    label {
      padding: 0 4px;
      font-size: 16px;
      color: #999;
    }

    &_textarea {
      margin: 0;
      padding: 0;

      textarea {
        border: 1px solid #ccc;
      }
    }

    &_full {
      grid-column: 1 / -1;
    }

    &_hidden {
      display: none;
    }
  }

  &__label {
    padding: 0 4px;
    font-size: 16px;
    font-weight: normal;
    color: #999;
  }

  &__attachments {
    grid-column: 1 / -1;

    &_hidden {
      display: none;
    }

    & > * + * {
      margin-top: 10px;
    }
  }

  &__imagesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

    & > * {
      flex-grow: 0;
      width: 100px;
      height: 100px;
    }
  }

  &__row {
    &_full {
      display: flex;
      grid-column: 1 / -1;
      gap: 20px;
      justify-content: center;
    }
  }

  &__button {
    flex: 1;
    max-width: none;
    margin: 0;
  }
}
