 .states {
     position: sticky;
     z-index: 10;
     top: 0;

     background: #fff;
     border-bottom: 1px solid rgb(0 0 0 / 10%);
     box-shadow: 0 3px 5px rgb(0 0 0 / 5%);

     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .container {
     padding: 10px 20px;
 }

 .container div {
     display: flex;
     gap: 10px;
     align-items: center;
 }

 .link:hover {
     color: #0088ee;
 }

 .xlsBtnBlock {
     padding: 10px 40px 10px 20px;
 }