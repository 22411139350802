.wrapper {
  max-width: 300px;
  padding: 20px;

  white-space: pre-wrap;

  background: white;
  border-radius: 0.2rem;
  box-shadow: 1px 1px 15px rgb(0 0 0 / 60%);
}
