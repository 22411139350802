@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap';

.wrapper {
  overflow: hidden;
  display: grid;

  grid-template-columns: 1fr;
  row-gap: 10px;
  width: 100%;
  max-height: 37px;
  padding: 9px 12px 11px;

  background-color: #fff;
  border: 1px solid rgb(208 208 208);
  border-radius: 2px;

  transition: all 0.3s ease-in-out;
}

.head {
  font-size: 15px;
  color: #404040;
  opacity: 0.5;
}

.content {
  overflow: auto;
  box-sizing: border-box;
  min-height: 25px;
  max-height: 400px;
  white-space: pre;
  border: 1px solid rgb(208 208 208);
  border-radius: 2px;
}