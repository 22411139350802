.feedback-input {
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding: 10px;

  border-left-style: solid;
  border-left-width: 2px;

  /* overflow: hidden; */

  /* height: 0; */
}

.quick-answers {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-width: 100%;
}

.form {
  display: flex;
}

.form__inputContainer {
  position: relative;
  display: flex;
  flex: 1;
}

.chan-facebook {
  border-left-color: #4267b2;
}

.chan-facebookcomments {
  border-left-color: #4267b2;
}

.chan-telegram, .chan-tgcomm {
  border-left-color: #44a7d9;
}

.chan-telegrambot {
  border-left-color: #44a7d9;
}

.chan-vkgroup {
  border-left-color: #07f;
}

.chan-vkcomm {
  border-left-color: #07f;
}

.chan-sms {
  border-left-color: #19b89f;
}

.chan-ok,
.chan-okgroup {
  border-left-color: #ff8a19;
}

.chan-viber {
  border-left-color: #9661ab;
}

.chan-livechat {
  border-left-color: #94bd5c;
}

.chan-whatsapp {
  border-left-color: #19e25b;
}

.chan-yt_live {
  border-left-color: #db2833;
}

.chan-smsmoney {
  border-left-color: #19ba9f;
}

.chan-smsMoney {
  border-left-color: #19ba9f;
}

.chan-smsrub {
  border-left-color: #19ba9f;
}

.chan-smsRub {
  border-left-color: #19ba9f;
}

.chan-fbinstagram {
  /* border-left-color: linear-gradient(180deg, rgba(133,69,221,1) 0%, rgba(214,81,173,1) 23%, rgba(252,133,75,1) 67%, rgba(253,212,122,1) 100%); */
  border-left-color: rgb(214 81 173 / 100%);
}

.chan-alisa {
  border-left-color: #7148ff;
}

.messageFiles {
  display: flex;
  gap: 0.6rem;
  padding-top: 0.6rem;
}

.filesInfo {
  display: flex;
  color: #524e4e;
  opacity: 0.33;
}