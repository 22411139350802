.tabs {
  display: flex;
  align-items: center;
  order: -2;
  background-color: #fff;
}

.tab {
  cursor: pointer;

  padding: 15px 20px;

  background-color: transparent;
  border-bottom: 3px solid transparent;

  transition: border-bottom-color 0.2s;
}

.tab__current {
  border-bottom-color: #08e;
}

.children {
  margin-right: 15px;
  margin-left: auto;
}
