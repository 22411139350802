.fileItem {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;

  width: max-content;

  color: #524e4e;

  opacity: 0.33;
}

.bigFileItem {
  position: relative;

  display: flex;
  gap: 0.3rem;
  align-items: center;

  padding: 8px;

  color: #524e4e;

  background: #e0e0e0;
  border-radius: 8px;
}

.fileName {
  overflow: hidden;

  width: 60px;

  font-size: 0.7rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bigFileItem .fileName {
  overflow: hidden;

  width: 100%;

  font-size: 0.7rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileItem:hover {
  opacity: 0.8;
}

.fileItemDeleteBtn {
  cursor: pointer;

  position: absolute;
  top: -10px;
  right: 0;

  display: none;

  padding: 0;
}

.fileItem:hover .fileItemDeleteBtn {
  display: block;
}
