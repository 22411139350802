.card {
    overflow: hidden;
    margin-top: 8px;
    padding: 15px 20px;
    color: rgb(0 0 0 / 87%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
        0 1px 1px 0 rgb(0 0 0 / 14%),
        0 1px 3px 0 rgb(0 0 0 / 12%);

    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-sizing: border-box;
}


.head {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    flex: 0 0 67px;
    text-align: center;
}

.profile {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile_image {
    min-width: 67px;
    display: flex;
    justify-content: center;
}

.profile_image img {
    border-radius: 50%;
    width: 40px;
}

.profile_data {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.name {
    color: #808080;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    padding-right: 5px;
}

.senderNumber {
    font-weight: 700;
    font-size: 16px;
}

.controls {
    display: flex;
    /* gap: 10px; */
}

.participationBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bodyCard {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.chat {
    margin-top: 20px;
    margin-left: 70px;
}


.editBtnBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    animation: h 0.5s;
}

@keyframes h {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}