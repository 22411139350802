.forecast {
  position: relative;
  font-weight: 600;

  &__time {
    position: absolute;
    top: 4px;
    left: 0;

    font-size: 12px;
    color: #888;
  }

  &__temperature {
    font-size: 16px;
    font-weight: 500;
  }

  &__iconContainer {
    box-sizing: border-box;
    width: 50px;
    height: 50px;

    & img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  &__condition {
    font-weight: 400;
  }

  &__stats {
    display: flex;
    grid-column: span 6;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }
}
