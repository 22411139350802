.chip {
  cursor: default;

  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  max-width: 100%;
  height: 32px;
  margin: 4px;
  padding: 0;

  font-size: 14px;
  line-height: 1;
  color: rgb(0 0 0 / 87%);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

  background-color: #e0e0e0;
  border: none;
  border-radius: 16px;
  outline: 0;

  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.chip-label {
  overflow: hidden;

  padding-right: 12px;
  padding-left: 12px;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.chip-delete {
  cursor: pointer;
  margin-right: 5px;
  padding: 5px;
}
