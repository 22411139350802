.list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.list li {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
}

.list li:nth-child(1) {
    padding: 10px 0;
}

.list li>* {
    display: flex;
    justify-content: center;
    flex: 1;
}

.tagBlock {
    width: max-content;
    margin: 20px 0;
}