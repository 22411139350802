.chart-legends {
  position: relative;
  z-index: 49;
  top: 40px;

  display: flex;
  justify-content: center;
}

.chart-legends span {
  color: rgb(50 50 50);
}

.chart-legends > div {
  position: relative;
  margin-right: 100px;
}

.chart-legends .chart__allMessages,
.chart-legends .chart__authors {
  font-weight: 500;
  color: #c23531;
}

.chart-legends > h4 {
  position: absolute;
}

.chart__allMessages-icon {
  display: inline-block;

  width: 25px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: -3px;

  background: #c23531;
  border-radius: 5px;
}

.chart__authors-icon {
  position: absolute;
  top: -3px;
  left: -30px;
}

.chart_label {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}
