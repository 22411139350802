.module-header {
  user-select: none;

  z-index: 11;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;
  margin-top: -40px;

  padding: 5px;

  text-align: left;

  background-color: rgb(255 255 255 / 92%);
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
}

.module-type-name {
  cursor: default;

  position: relative;

  overflow: hidden;
  display: inline-block;
  flex-shrink: 0;

  max-width: 158px;
  padding: 0 10px;

  font-size: 16px;
  color: rgb(0 0 0 / 75%);
  white-space: nowrap;

  opacity: 0.75;
}
