.lp-settings__title {
  margin: 1em 0;
  padding: 1em 0;
  font-weight: 400;
  border-top: 1px solid #ddd;
}

.select-msg-view {
  padding-bottom: 1em;
  text-align: center;
}

.select-msg-view img {
  cursor: pointer;

  width: 104px;

  border: 2px solid #fff;
  border-radius: 10px;

  transition: border-color 0.15s ease-in-out;
}

.select-msg-view input:checked ~ img {
  border: 2px solid #3ea7f5;
}

.rotated-img {
  transform: rotate(180deg);
}

.lp-settings__label {
  display: inline-block;
  font-size: 0.85em;
  opacity: 0.75;
}

.lp-settings__label:first-child {
  margin-right: 5px;
}

.lp-settings__input {
  display: none;

  box-sizing: border-box;
  width: calc(100% - 15px);
  max-width: 200px;
  margin: 1em auto;
  padding: 0.3em;

  font-size: 1.2em;
}

.lp-settings__span {
  display: block;
}
