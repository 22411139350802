.profile-card__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  padding: 25px;
  padding-bottom: 0;
}

.profile-card__img-wrapper {
  flex: 0 10 auto;
  padding-bottom: 20px;
}

.profile-card__failed-load {
  width: 100%;
  padding: 15px 0;

  color: #fff;
  text-align: center;

  background-color: #de4646;
}

/* profile messages */
.profile-card__messages-wrapper {
  padding: 5px 25px;
}

.profile-card__messages {
  width: 100%;
  min-height: 100px;
  margin-bottom: 60px;

  background: #fff;
  border-radius: 15px;
}

.profile-card__messages-header {
  width: 100%;
  height: fit-content;
}

.profile-card__messages-header h3 {
  margin: 0;
  padding: 5px 0 0 16px;
  font-weight: 500;
  color: #4c4c4c;
}

.profile-card__messages-nomessage {
  width: 100%;
  margin: 1em 0;
  padding: 5px 0 20px;
  text-align: center;
}

.profile-card__media-wrapper {
  padding: 0 25px;
}

/* учесть для сообщений */

/* .lbMsg.profile-card-lbMsg> :first-child .msg {
  box-shadow: none;
  border-bottom: 1px solid #d3d3d3;
}

.lbMsg.profile-card-lbMsg> :first-child .msg:last-child {
  border-bottom: none;
}

.profile-card-lbMsg> :first-child .msg .action-mnu,
.profile-card__messages .export-to-titr,
.profile-card-lbMsg> :first-child .msg .minimize-btn {
  display: none;
} */

.info {
  position: sticky;
  z-index: 1;
  top: 5px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  margin: 8px 0 4px;

  font-size: 12px;
}

.date {
  min-width: 120px;
  text-align: center;
}

.info > * + * {
  margin-left: 10px;
}
