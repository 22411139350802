.pane {
  padding: 10px;
}

.title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.rangeTimeWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px 20px;
  padding: 0px 0px 10px;
}

/* .rangeTimeWrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #fff, transparent);
} */

.inputs-wrapper {
  display: flex;
  gap: 5px;
}

.inputItem {
  display: flex;
  align-items: center;
  background-color: #00000040;
  border-radius: 50px;
  padding: 4px 10px 4px 10px;
}

.inputTitle {
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: -2px;
}

.inputs-wrapper input[type='time'].searchActive, .inputs-wrapper input[type='date'].searchActive {
  background-color: #00000040;
}

.inputs-wrapper input[type='date'], .inputs-wrapper input[type='time'] {
  color: #fff;
  background: #c2c2c25e;
  border-radius: 10px;
  min-height: 25px;
  margin-left: 4px;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid transparent;
  min-width: 75px;
}

.inputs-wrapper input[type='time']::-webkit-datetime-edit-fields-wrapper, .inputs-wrapper input[type='date']::-webkit-datetime-edit-fields-wrapper {
  margin-bottom: -4px;
}

.inputs-wrapper input[type='date']:focus-visible, .inputs-wrapper input[type='time']:focus-visible {
  outline: none;
  border: 1px solid #fff;
}

.inputs-wrapper input[type='time']:invalid, .inputs-wrapper input[type='date']:invalid {
  outline: none;
  border: 1px solid #de4646;
  background: #de4646;
}

.inputs-wrapper input[type='time']::-webkit-calendar-picker-indicator {
  margin-inline-start: 0;
}

.inputs-wrapper input[type='date']::-webkit-calendar-picker-indicator, .inputs-wrapper input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.inputs-wrapper input[type='date']::-webkit-datetime-edit-month-field, .inputs-wrapper input[type='date']::-webkit-datetime-edit-year-field {
  color: #fff;
}

.inputs-wrapper input[type='date']::-webkit-calendar-picker-indicator:hover, .inputs-wrapper input[type='time']::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;

}

.selected {
  color: white;
  background-color: #08e;
}

.btnWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrowUpBlock {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.statisticsBlock {
  display: flex;
  justify-content: center;
  min-height: 20px;
}