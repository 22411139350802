.form {
  display: flex;
  gap: 2rem;
  align-self: flex-start;

  &__newProfile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    max-width: 70px;
    padding-top: 0.2rem;
  }

  &__buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__add {
    margin-top: 0.5rem;

    &_active {
      margin-top: 0;
    }
  }

  &__add,
  &__done {
    padding: 1rem 0;
    border: 0;
    transition: color 0.2s;

    i {
      transition: transform 0.5s;
    }

    &_active {
      color: rgb(221 27 27);

      i {
        transform: rotate(45deg);
      }
    }
  }
}
