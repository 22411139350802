.action-menu-list {
  padding: 5px 10px 10px;

  list-style: none;

  background-color: rgb(255 255 255 / 92%);
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 5px;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 30%);
}

.separator {
  height: 1px;
  margin: 5px -10px;
  background-color: rgb(0 0 0 / 20%);
}

.menu-item {
  padding: 8px 22px;
}

/* 
.menu-item:hover:not(.disabled),
.menu-item.active {
  cursor: pointer;
  color: white;
  background-color: #0088ee;
}

.menu-item.disabled {
  opacity: 0.5;
} */
