.item {
  font-size: 14px;
  color: rgb(0 0 0 / 75%);
}

label {
  cursor: pointer;
}

.inputTag {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  width: 100%;
  margin: 2px;
  padding: 4px;

  border: solid 1px #e2e2e2;
  border-radius: 3px;
}

.handlerTag {
  resize: none;

  flex: 1;

  min-width: 200px;
  min-height: max-content;
  margin: 2px;
  padding: 6px 0;

  font-family: inherit;
  font-size: inherit;

  background-color: transparent;
  border: none;
  outline: none;
}

.handlerTag.multiline {
  min-width: 80%;
}

.emojiPicker {
  margin-left: auto;
}
