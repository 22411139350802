.action-export {
  float: right;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;

  font-size: 0.85em;
}

.button {
  padding: 0.25vw 0.4vw;
}

.button_small {
  overflow: hidden;
  max-width: 10px;
}

.char-counter {
  align-self: center;

  margin-right: 30px;

  color: #08e;
  text-align: center;

  opacity: 0.75;
}

.char-counter.warning {
  color: rgb(255 51 51);
}
