.participation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #808080;
    gap: 10px;
    margin-right: 10px;
}

.participation__btnBlock {
    display: flex;
    gap: 10px;
    align-items: center;
}

.participation__btn {
    cursor: pointer;
    color: #08e;
    border-bottom: 1px solid transparent;
    white-space: nowrap;

}

.participation__btn.active {
    color: #b8c;
    border-bottom: 1px solid #b8c;
}

.participation__btn:hover {
    color: #b8c;
}

.title {
    white-space: nowrap;
}