.drop-area {
  position: absolute;
  z-index: 41;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #fff;

  background: #00000061;
}

.drop-area.is-hovered {
  background-color: #000000a6;
}
