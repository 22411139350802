.chart-timer {
  position: absolute;
  z-index: 100;
  top: 105px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 400px;

  background: rgb(255 255 255 / 90%);
}
