.pane {
  padding: 10px;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 1em;
  font-size: 1.4em !important;
  color: #fff !important;
}

.channel-mode-btn {
  border: 2px solid #808080;
  border-radius: 50%;
}

.channel-mode-btn:hover {
  border-color: #ccc;
}

.channel-mode-btn.selected {
  border-color: #fff;
}
