:root {
  --dots-loader-color: #28292f;
  --dots-loader-size: 20px;
}

.loader div {
  position: absolute;
  transform: translate(40px, 40px) scale(1);

  box-sizing: content-box;
  width: 20px;
  height: 20px;

  background: #28292f;
  border-radius: 50%;

  animation: loader 2.272727272727273s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.loader div:nth-child(1) {
  transform: translate(74px, 40px) scale(1);
  background: #28292f;
  animation: loader-r 0.5681818181818182s infinite cubic-bezier(0, 0.5, 0.5, 1),
    loader-c 2.272727272727273s infinite step-start;
}

.loader div:nth-child(2) {
  background: #28292f;
  animation-delay: -0.5681818181818182s;
}

.loader div:nth-child(3) {
  background: #28292f;
  animation-delay: -1.1363636363636365s;
}

.loader div:nth-child(4) {
  background: #28292f;
  animation-delay: -1.7045454545454546s;
}

.loader div:nth-child(5) {
  background: #28292f;
  animation-delay: -2.272727272727273s;
}

.loading {
  overflow: hidden;
  display: inline-block;

  width: 44px;
  height: 44px;

  background: none;
}

.loader {
  position: relative;
  transform-origin: 0 0;
  transform: translateZ(0) scale(0.44);

  width: 100%;
  height: 100%;

  backface-visibility: hidden;

  /* see note above */
}

.loading-wrapper {
  display: flex;
  justify-content: center;
}

@keyframes loader {
  0% {
    transform: translate(6px, 40px) scale(0);
  }

  25% {
    transform: translate(6px, 40px) scale(0);
  }

  50% {
    transform: translate(6px, 40px) scale(1);
  }

  75% {
    transform: translate(40px, 40px) scale(1);
  }

  100% {
    transform: translate(74px, 40px) scale(1);
  }
}

@keyframes loader-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }

  100% {
    transform: translate(74px, 40px) scale(0);
  }
}

@keyframes loader-c {
  0% {
    background: #28292f;
  }

  25% {
    background: #28292f;
  }

  50% {
    background: #28292f;
  }

  75% {
    background: #28292f;
  }

  100% {
    background: #28292f;
  }
}
