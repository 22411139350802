.progress {
  position: relative;

  width: 100%;
  min-width: auto;
  max-width: none;
  padding-right: 10px;
  padding-left: 15px;
}

.progress_bar {
  cursor: pointer;

  position: relative;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);

  width: 95%;
  width: 100%;
  height: 2px;
  margin: auto;

  background: #d8d8d8;
  background: none;
  border-radius: 0.25rem;
}

.progress_bottom {
  height: 3px;
  background: rgb(216 216 216);
}

.progress_line,
.progress_control {
  cursor: pointer;
  position: absolute;
  transform: translateY(-75%);
}

.progress_line {
  top: 8px;
  transform: translateY(-350%);

  overflow: hidden;

  width: 0;
  height: 2px;

  background: linear-gradient(to right, rgb(80 170 238), rgb(0 136 238));
  border-radius: 0.25rem;
}

.progress_control {
  z-index: 3;
  top: -4px;
  top: 4px;
  left: 0;

  box-sizing: border-box;
  width: 9px;
  height: 9px;
  padding: 5px;

  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgb(0 0 0 / 15%), 0 2px 4px rgb(0 0 0 / 15%);
}

.progress_line:focus,
.progress_control:focus,
.progress_line:hover,
.progress_control:hover {
  background: rgb(0 136 238);
}
