/* PLAYER */

.blue {
  background: #08f;
}

.grey {
  background: #404040;
}

.player * {
  font-size: 14px !important;
  transition: none;
}

.player {
  user-select: none;

  box-sizing: border-box;
  min-width: 20rem;
  max-width: 40rem;
  max-width: 100%;
  margin: 0 10px;
  padding: 8px 16px;

  font-size: 14px;
  color: #55606e;

  background: #fff;
  border-radius: 4px;
  box-shadow: none;
}

.player .title {
  display: inline-flex;
  display: none;
  line-height: 1.5rem;
}

.artist {
  display: inline-flex;
  font-weight: bold;
  line-height: 1.5rem;
}

.time {
  display: contents;
  font-size: 1rem;
}

.controls {
  display: flex;
  align-items: center;
  min-height: 26.09px;
}

.spinning {
  display: inline-block !important;

  animation-name: spinner;
  animation-duration: 600ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.volume a {
  position: relative;
  transition: color 0.2s;
}

.volume>a {
  position: relative;
  z-index: 1;
}

.volume:hover>a {
  color: #e8e8e8;
}

a.volume {
  z-index: 90;
}

audio,
audio:active {
  border: none;
  outline: none;
}