.container {
  user-select: text;

  position: relative;

  max-width: 100%;
  min-height: 70px;
  margin-top: 5px;
  margin-left: 0;
  padding: 7px 14px;

  background-color: white;
  border-bottom: 1px solid #ccc;
  border-radius: 2px;

  transition: height, margin, padding 0.3s;
}

.weather__actions {
  min-width: 150px;

  /* width: 100%; */
}

.weather {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.weather thead {
  border-bottom: 1px solid #dedede;
}

.weather tfoot {
  border-top: 1px solid #dedede;
}

.weather__th {
  color: #999;
  text-align: left;
  vertical-align: top;
}

.weather__th + .weather__th {
  padding: 16px 0 10px 10px;
}

.weather th + th {
  padding-left: 10px;
}

.weather td + td {
  padding-left: 10px;
}

.weather__date {
  display: flex;
  align-items: baseline;
}

.weather__day {
  font-size: 40px;
  font-weight: 500;
  color: rgb(0 0 0 / 75%);
}

.weather__month {
  margin-left: 1em;
  font-size: 18px;
  font-weight: 500;
  color: #999;
}

.weather tbody tr > * {
  padding-top: 10px;
  padding-bottom: 10px;
}
