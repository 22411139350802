/* md-radio */

.md-radio__label {
  cursor: pointer;

  position: relative;

  display: block;

  padding-left: 1.6rem;

  color: #333;
  text-align: left;
}

.md-radio__input {
  position: absolute;
  left: 0;
  width: auto;
  opacity: 0.00000001;
}

.md-radio__radio {
  font-size: 15px !important;

  /* margin-bottom: 1rem; */
}

.md-radio__radio .md-radio__helper {
  cursor: pointer;
  user-select: none;

  position: absolute;
  top: -0.25rem;
  left: -0.25rem;

  display: block;

  font-size: 1rem;
  color: #999;
}

.md-radio__radio .md-radio__helper::before,
.md-radio__radio .md-radio__helper::after {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 1rem;
  height: 1rem;
  margin: 0.25rem;

  border: 0.125rem solid currentcolor;
  border-radius: 50%;

  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
}

.md-radio__radio .md-radio__helper::after {
  transform: scale(0);
  background-color: #08e;
  border-color: #08e;
}

.md-radio__radio label:hover .md-radio__helper {
  color: #08e;
}

.md-radio__radio input:checked ~ .md-radio__helper::after {
  transform: scale(0.5);
}

.md-radio__radio input:checked ~ .md-radio__helper::before {
  color: #08e;
}
