.weather-card {
  margin-top: 8px;
  padding: 8px 20px;
}

.weather-card > div {
  margin-top: 7px;
}

.w48 {
  width: 48%;
}
