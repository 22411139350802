.profile-card__media-item {
  cursor: pointer;

  position: relative;

  overflow: hidden;

  width: 60px;
  height: 70px;

  color: #474747;

  background: #dcdcdc;
  border-radius: 10px;
}

.profile-card__media-item-music-icon {
  display: block !important;
  width: fit-content;
  margin: auto;
}

.media-item__play-icon {
  margin-top: 5px;
  font-size: 40px !important;
}

.profile-card__media-item-music-desc {
  position: absolute;
  right: 0;
  bottom: 4px;
  left: 0;

  width: fit-content;
  margin-right: auto;
  margin-left: auto;

  font-size: 13px;
  font-weight: 500;
}

.audio-icon__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  text-align: center;
}
