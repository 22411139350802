.alert {
  /* display: inline-flex; */
  flex-wrap: inherit;

  padding: 16px 24px;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;

  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);

  /* align-items: center; */

  /* justify-content: space-between; */
}

.danger {
  background-color: #de4646;
}

.success {
  background-color: #4eb152;
}

.info {
  background-color: #202124;
}

.warning {
  background-color: #ffa000;
}
