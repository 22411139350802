.wrapper {
  position: fixed;
  z-index: 1001;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px;

  background-color: rgb(0 0 0 / 80%);
  outline: none;
}

.header {
  position: relative;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  display: flex;
  gap: 20px;
  align-items: center;
}

.user-name {
  display: block;

  font-size: 25px;
  line-height: 1.5;
  color: #ccc;
  text-decoration: none;
}

.publish-date {
  margin: 0;
  font-size: 18px;
  color: #a1a1a1;
}

.btn {
  color: #ccc;
  opacity: 1;
}

.content-wrapper {
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.content img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-window-image__desc {
  color: #ccc;
}
