.pane {
  padding: 10px;
}

.title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.search-field {
  width: 100%;
  margin: 0;
  font-size: 1.5em;
  border: 2px solid transparent;
}

.note {
  margin: 1em 0;
}
