.center {
  text-align: center;
}

.Loader {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.Loader div {
  position: absolute;

  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;

  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.Loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;

    width: 0;
    height: 0;

    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;

    width: 72px;
    height: 72px;

    opacity: 0;
  }
}
