.profile-card__info {
  flex: 1 1 auto;
  width: 50%;
  padding: 0 20px;
}

.profile-card__info-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.profile-card__info-name {
  padding-right: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #404040;
}

.profile-card__info-location {
  font-size: 14px;
  color: #858585;
}

.phone-wrapper {
  position: relative;
  top: 5px;
  color: #777;
}

.profile-card__info-phone {
  user-select: text;
  margin-left: 9px;
}

.profile-card__activity {
  display: flex;
  margin-top: 10px;
}

.profile-card__activity div:first-child {
  margin-right: 15%;
}

.profile-card__info-header {
  margin: 1em 0;
  font-size: 14px;
  line-height: 0.7;
  color: #858585;
}

.profile-card__info-desc {
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  transition: none;
}

.string-loader::before {
  content: '';

  position: relative;
  position: absolute;
  top: -5px;
  left: 0;

  width: 5em;
  height: 0.5em;

  background-color: #ababab;
  border-radius: 5px;

  animation: load7 2.5s infinite ease-in-out;
  animation-fill-mode: both;
  animation-delay: -0.32s;
}

.string-loader {
  position: relative;
  transform: translateZ(0);

  margin: 2px auto;

  font-size: 10px;
  color: #fff;
  text-indent: -9999em;

  animation-delay: -0.16s;
}

@keyframes load7 {
  0% {
    transform: translateX(0);
    width: 1em;
  }

  25% {
    transform: translateX(0);
    width: 5em;
  }

  50% {
    transform: translateX(5em);
    width: 1em;
  }

  75% {
    transform: translateX(0);
    width: 5em;
  }
}

@keyframes load7 {
  0% {
    transform: translateX(0);
    width: 1em;
  }

  15% {
    width: 4em;
  }

  30% {
    transform: translateX(0);
    width: 5em;
  }

  50% {
    transform: translateX(5em);
    width: 1em;
  }

  80% {
    transform: translateX(0);
    width: 5em;
  }

  100% {
    width: 1em;
  }
}
