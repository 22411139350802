.media-video {
  position: relative;
  overflow: hidden;
  max-width: 256px;
  background-color: #f9f2fc;
  /* background-image: url("assets/images/icons/media-video@2x.png"); */
  border-radius: 12px;
}

.media-video video {
  display: block;
  width: 100%;
  height: auto;
}

.broadcast {
  max-width: none;
  height: 198px;
  width: 352px;
}