.pane {
  padding: 10px;
}

.title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.selected {
  background-color: #fff !important;
}
