.module-common {
  position: absolute;
  z-index: 50;
  top: 40px;
  left: 0;
  overflow-y: auto;
  max-height: 90%;
  display: block;

  width: 100%;
  padding: 25px 15px;

  background-color: white;
  box-shadow: 0 20px 20px rgb(0 0 0 / 10%);
}