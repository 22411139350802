.body {
  pointer-events: none;
  height: 100%;
  min-height: 100%;
  opacity: 0;
}

.body__current {
  pointer-events: initial;
  order: -1;
  opacity: 1;
}

.body_withTabs {
  height: calc(100% - 50px);
  min-height: calc(100% - 50px);
}
