.audio {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 6px 0;
}

.audio__track {
  display: flex;
  gap: 10px;
}

.audio__label {
  margin-top: 5px;
  font-size: 16px;
}

.audio__select-button {
  flex: 1;
  max-width: none;
}

.audio__select-variants {
  flex: 1;
  max-width: none;
}

.audio__select-variant {
  min-height: 40px;
}

.effects {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
