/* text */
.text {
  user-select: text;

  overflow: hidden;

  max-height: unset;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #333;
  letter-spacing: -0.035em;
  word-break: break-word;
  white-space: pre-line;
}

.text:focus {
  outline: #dfdfdf auto 1px;
}

.text.editable {
  /* margin-top: 15px; */
  padding: 7px;
}

/* .text.show-full-text {
} */

.text.small-text {
  max-height: 65px;
}
