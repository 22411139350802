.profileChooser {
  margin-bottom: 1rem;

  &__title {
    display: none;
  }

  &__profilesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    min-height: 8.5rem;
  }

  &__noProfiles {
    align-self: flex-start;
    margin-top: 1.5rem;
    font-size: 20px;
    text-align: center;
  }

  @media screen and (max-width: 60em) {
    font-size: 1.2rem;
  }
}
