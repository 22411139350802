.search-panel {
  position: absolute;
  z-index: 101;

  overflow: hidden;

  width: 100%;
  padding-bottom: 10px;

  font-size: 0.85em;
  color: white;
  text-align: center;

  background-color: rgb(100 100 100 / 80%);
  box-shadow: 0 3px 5px rgb(0 0 0 / 15%);

  transition: height 0.25s ease-in-out;
}

.search-panel.collapsed {
  max-height: 36px;
}

.wrapperStatistics {
  position: absolute;
  display: flex;
  align-items: center;
  height: 35px;
  right: 38px;
}