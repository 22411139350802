.wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px;
}

.content {
  padding: 10px;
  color: gray;
  background-color: #f1f0f0;
  border-radius: 10px 10px 0;
}

.loading {
  clip-path: inset(0 2ch 0 0);
  animation: l 1.5s steps(8) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
