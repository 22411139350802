.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70px;

  font-weight: bold;
  color: #333;

  background-color: transparent;

  &__more {
    position: absolute;
    top: 0.2rem;
    right: 0;

    width: 2rem;
    height: 2rem;

    opacity: 0;
    background-color: #fff;
    border: 1px solid currentcolor;
    border-radius: 6px;

    transition: opacity 0.3s;

    &_active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__imageContainer {
    position: relative;

    overflow: hidden;
    display: flex;
    justify-content: center;

    box-sizing: content-box;
    width: 70px;
    height: 70px;
    padding: -0.2rem;

    border: 0.2rem solid transparent;
    border-radius: 10px;

    transition: border 0.3s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_current {
    .profile__imageContainer {
      border: 0.2rem solid #1e68d7;
    }
  }

  &__name {
    overflow: hidden;
    align-self: stretch;

    max-width: 6.5rem;
    min-height: 1.25rem;
    margin-top: 1rem;

    font-weight: normal;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    background: none;
  }

  &__edit {
    cursor: pointer;

    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;

    color: #666;

    background-color: transparent;
    border-radius: 50%;

    transition: opacity 0.2s;

    svg {
      margin: 0.25rem;
      fill: #fff;

      &:nth-of-type(1) {
        width: 1rem;
        height: 1rem;
      }

      &:nth-of-type(2) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__options {
    overflow: hidden;
    background: #fcfcfc;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  &__option {
    cursor: pointer;
    padding: 10px;
    text-align: center;

    &_delete {
      color: red;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      background-color: #eaeaea;
    }
  }
}
