.settings-title {
  font-size: 3vmax;
  font-weight: 200;
  color: gray;
  text-align: center;
}

.active-button {
  background-color: rgb(0 96 198 / 20%);
}
