.container {
  display: inline-block;
  vertical-align: top;
}

.picture-loader.invalidImg {
  border: 0.5px solid #ff2525;
  border-radius: 8px;
}

.picture-loader {
  position: relative;

  overflow: hidden;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
}

.picture-loader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.picture-loader__controls {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  height: 100%;

  background: #00000070;
}

.reactCrop {
  display: flex;
  justify-content: center;
  max-height: 60vh;
}

.reactCrop>* {
  height: 100%;
}

.reactCrop__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.resolutionInfo {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 30px;
  border: none;
  font-size: 18px;
  color: #08e;
}