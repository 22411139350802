.progress {
  bottom: 0;

  /* position: absolute; */
  width: 100%;
  height: 5px;
  margin: 0.5em 0;

  background-color: #c8ebff;
}

.progress span {
  display: block;
  width: 1px;
  height: 100%;
  background-color: #08e;
}
