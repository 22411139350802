.wind {
  display: flex;

  &__direction {
    display: flex;
    margin-left: 6px;
    font-weight: 300;

    & > * + * {
      margin-left: 3px;
    }
  }

  &__iconContainer {
    color: #bbb;
  }
}
