.questionWrapper {
    padding: 24px 18px 15px;
}

.imageAndQuestionBlock {
    display: flex;
    align-items: flex-start;
}

.question {
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    margin: 0;
    box-shadow: none;
}