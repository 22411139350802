:root {
  --default-btn-color: #08e;
  --default-btn-color-hover: #08e;
  --default-btn-color-text: #08e;
  --default-btn-color-text-hover: #fff;
  --default-btn-color-active: #08e;
  --default-text-btn-color-hover: #08e;
  --default-text-btn-color-active: #08e;
}

.button {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 60px;
  max-width: 350px;

  font-family: inherit;
  font-size: 14px;

  /* background: #4676D7; */
  color: var(--default-btn-color-text);
  text-align: center;

  appearance: none;
  border: 0;
  border-radius: 3px;
}

.button__text {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button__loader {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.button:hover {
  /* background: var(--default-btn-color); */
}

.button:focus {
  outline: none;

  /* box-shadow: 0 0 0 4px #cbd6ee; */
}

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.button__icon {
  display: block;
}

.button__icon svg {
  display: inline-block;
  vertical-align: middle;
  fill: #fff;
  transition: fill 0.2s;
}

.button__start-icon > * {
  margin-right: 0.25rem;
}

.button__end-icon > * {
  margin-left: 0.25rem;
}

.button--only-icon > * {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.button--only-icon .button__start-icon > * {
  margin: 0;
}

.button--only-icon {
  min-width: initial;
  text-align: center;

  /* padding: 10px 14px; */

  /* padding: 0 !important; */
}

.button--only-icon .button__text {
  display: none;
}

.button--contained {
  background-color: var(--default-btn-color);
}

.button--contained:hover {
  background-color: var(--default-btn-color-hover);
}

.button--contained:active {
  background-color: var(--default-btn-color-active);
}

.button--outlined {
  background: transparent;
  border: 1px solid var(--default-btn-color);
}

.button--outlined:hover {
  color: var(--default-btn-color-text-hover);
  background: var(--default-btn-color-hover);
}

.button--outlined:active {
  color: var(--default-btn-color-text-hover);
  background-color: var(--default-btn-color-active);
}

.button--active.button--outlined {
  color: var(--default-btn-color-text-hover);
  background: var(--default-btn-color-hover);
}

.button--text {
  justify-content: center;
  color: var(--default-btn-color-text);
  background: transparent;
}

.button--text:hover {
  background: var(--default-text-btn-color-hover);
}

.button--text:active {
  background-color: var(--default-text-btn-color-active);
}

.button--message-action {
  padding: 0;
  color: var(--default-btn-color-text);
  opacity: 0.33;
  background: transparent;
}

.button--message-action:hover {
  opacity: 0.75;
}

.button--icon {
  color: var(--default-btn-color-text);
  opacity: 0.75;
  background: transparent;
}

.button--icon:hover {
  color: var(--default-btn-color-hover);
  opacity: 1;
  background: var(--default-text-btn-color-hover);
}

.button--small {
  padding: 5px 9px;
  font-size: 12px;
}

.button--small .button__start-icon svg,
.button--small .button__end-icon svg {
  /* width: 16px;
    height: 16px; */
}

.button--medium {
  padding: 5px 10px;
  font-size: 14px;
}

.button--medium .button__start-icon svg,
.button--medium .button__end-icon svg {
  /* width: 20px;
    height: 20px; */
}

.button--large {
  padding: 7px 21px;
  font-size: 16px;
}

.button--large .button__start-icon svg,
.button--large .button__end-icon svg {
  /* width: 24px;
    height: 24px; */
}

.button--google {
  /* color: #757575; */
  color: var(--default-btn-color-text);

  background-color: var(--default-btn-color);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%);

  transition: all 400ms ease 0s;
}

.button--google:hover {
  box-shadow: 0 3px 8px rgb(117 117 117 / 50%);
}

.button--google.button--small {
  font-size: 11px;
}

.button--google.button--medium {
  padding: 10px 20px;
  font-size: 14px;
}

.button--google.button--large {
  font-size: 15px;
}

.button--google .button__start-icon > * {
  margin-right: 15px;
}

.button-progress {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 0%;
  height: 100%;

  background: #81c2f56e;
}

/* stop animated button */

.stop-active {
  animation: stop-btn-active 1s forwards;
}

.stop-active-done {
  width: 100%;
}

.stop-unactive {
  animation: stop-btn-unactive 1s forwards;
}

.stop-unactive-done {
  width: 0%;

  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@keyframes stop-btn-active {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes stop-btn-unactive {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

/* cycle animated button */
.cycle-active {
  animation: cycle-btn-active 1s forwards;
}

.cycle-active-done {
  animation: cycle-btn-active 1s infinite;
}

.cycle-unactive {
  width: 0%;
}

.cycle-unactive-done {
  width: 0%;
}

@keyframes cycle-btn-active {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
