.picker {
  position: relative;

  &__label {
    position: absolute;
    top: -20px;
    left: 0;

    padding: 2px;

    color: #999;
  }

  &__input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}
