.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;

  padding-bottom: 2rem;
}

.configuration {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding-bottom: 20px;
}

.configuration__control {
  display: flex;
  align-items: center;
}

.words {
  flex: 1;
}
