.rate_picker {
  position: relative;
}

.rate_list {
  position: absolute;
  z-index: 10;
  top: -17px;
  top: -10px;
  left: -159px;

  overflow: hidden;
  display: flex;
  align-items: center;

  color: #fff;

  background: #404040;
  border-radius: 4px;
  box-shadow: 13px 2px 20px 0 rgb(0 0 0 / 30%);
}
