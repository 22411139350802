#list-pane {
  display: flex;
  flex: 1;
  flex-direction: column;

  height: calc(100% - 50px);
  min-height: calc(100% - 50px);
}

.messages {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
