.welcome-nav-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.link.b1-2 {
  cursor: pointer;

  margin-bottom: 30px;
  padding: 0 0 20px;

  font-size: 1.7vw;
  color: rgb(0 0 0 / 50%);

  border-radius: 5px;

  transition: background-color 0.5s ease-in-out;
}

.link.b1-2 span {
  display: block;

  width: 14vw;
  height: 14vw;
  margin: auto;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.link.b1-2:hover {
  background-color: rgb(0 100 255 / 10%);
  transition: background-color 0.5s ease-in-out;
}

.link.b1-2.link-ia:hover {
  color: #08e;
}

.link.b1-2.link-ia span {
  background-image: url('assets/images/icons/interactives@2x.png');
}

.link.b1-2.link-prefs:hover {
  color: #cc64b7;
}

.link.b1-2.link-prefs span {
  background-image: url('assets/images/icons/preferences@2x.png');
}

.b1-2 {
  display: inline-block;
  width: 47%;
  text-align: center;
  vertical-align: top;
}
