.md__form-group {
  position: relative;
}

.md__form-group input {
  height: 1.9rem;
}

.md__form-group textarea {
  resize: none;
}

.md__form-group .control-label {
  pointer-events: none;

  position: absolute;
  z-index: 1;
  top: 0.25rem;

  padding-left: 0.125rem;

  font-size: 1rem;
  font-weight: normal;
  color: #b3b3b3;

  transition: all 0.28s ease;
}

.md__form-group .bar {
  position: relative;
  display: block;
  border-bottom: 0.0625rem solid #999;
}

.md__form-group.md__search-input .bar {
  border-bottom: none;
}

.md__form-group.md__search-input.active {
  width: 30%;
}

.md__form-group input[type='file'] ~ .bar {
  display: none;
}

.md__form-group.md__search-input.active .bar {
  border-bottom: 0.0625rem solid #999;
}

.md__form-group .bar::before {
  content: '';

  position: absolute;
  z-index: 2;
  bottom: -0.0625rem;
  left: 50%;

  width: 0;
  height: 0.125rem;

  background: #08e;

  transition: left 0.28s ease, width 0.28s ease;
}

.md__form-group input,
.md__form-group textarea {
  display: block;

  width: 100%;
  padding: 5px 5px 7px;

  font-size: 12px;
  line-height: 1.4;
  color: transparent;

  background: none;
  border-color: transparent;
  border-width: 0;
  box-shadow: none;

  transition: all 0.28s ease;
}

.md__form-group.md__search-input {
  position: relative;
  width: 20px;
  transition: all 0.3s;
}

.md__form-group.md__search-input input {
  width: 76%;
  padding: 0;
  padding-left: 30px;
  font-size: 14px;
}

.md__form-group.md__search-input .md__search-icon {
  cursor: pointer;

  position: absolute;
  bottom: 6px;
  left: 0;

  font-size: 18px;
  color: #08e;
}

.md__form-group.md__search-input .active-state {
  content: '';

  position: absolute;
  top: 7px;
  left: 14px;

  width: 6px;
  height: 6px;

  background: #ff9898;
  border-radius: 50%;
}

.md__form-group input:focus ~ .md__search-icon {
  color: #08e;
}

.md__form-group.md__search-input .md__clear-icon {
  cursor: pointer;

  position: absolute;
  right: 4px;
  bottom: 6px;

  display: none;

  font-size: 16px;
  color: #bec3ca;
}

.md__form-group.md__search-input .md__clear-icon:hover {
  color: #83888e;
}

.md__form-group.md__search-input.active .md__clear-icon {
  display: block;
}

.md__form-group input[type='file'] {
  line-height: 1;
}

.md__form-group input:focus,
.md__form-group input:valid,
.md__form-group input.form-file,
.md__form-group input.has-value,
.md__form-group textarea:focus,
.md__form-group textarea:valid,
.md__form-group textarea.form-file,
.md__form-group textarea.has-value {
  color: #333;
}

.md__form-group input:focus ~ .control-label,
.md__form-group input:valid ~ .control-label,
.md__form-group input.form-file ~ .control-label,
.md__form-group input.has-value ~ .control-label,
.md__form-group textarea:focus ~ .control-label,
.md__form-group textarea:valid ~ .control-label,
.md__form-group textarea.form-file ~ .control-label,
.md__form-group textarea.has-value ~ .control-label {
  top: -1rem;
  left: 0;
  font-size: 0.8rem;
  color: gray;
}

.md__form-group input:focus,
.md__form-group textarea:focus {
  outline: none;
}

.md__form-group input:focus ~ .control-label,
.md__form-group textarea:focus ~ .control-label {
  color: #337ab7;
}

.has-error .bar::before {
  left: 0;
  width: 100%;
  background: #d9534f;
}

.md__form-group input:focus ~ .bar::before,
.md__form-group textarea:focus ~ .bar::before {
  left: 0;
  width: 100%;
}

.has-error .legend.legend,
.has-error.md__form-group .control-label.control-label {
  color: #d9534f;
}

.has-error.md__form-group .form-help {
  color: #d9534f;
}
