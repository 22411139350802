.main-chat_datetime {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  -webkit-box-align: center;
}

.main-chat_datetime-line {
  flex: 1;

  width: auto;
  height: 1px;

  background-color: #e5e5e5;

  -webkit-box-flex: 1;
}

.main-chat_datetime-info {
  cursor: pointer;

  margin: 0 20px;

  font-size: 14px;
  font-weight: 400;
  color: #8a8d91;
}
