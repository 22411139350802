.fileUploadContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  height: 16rem;
  margin: 5px 0;
  padding: 35px 20px;
  text-align: center;
  background-color: white;
  border: 2px dotted lightgray;
  border-radius: 6px;
}

.invalid {
  border-color: #ff2525;
}

.formField {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  font-size: 18px;
  text-transform: none;
  opacity: 0;
  border: none;
  &:focus {
    outline: none;
  }
}

.inputLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: black;
}

.uploadFileBtn {
  cursor: pointer;

  position: relative;
  z-index: 1;

  overflow: hidden;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  min-width: 300px;
  padding: 1.1em 2.8em;
  padding-right: 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #3498db;
  text-align: center;
  text-transform: uppercase;

  appearance: none;
  background-color: transparent;
  border: 2px solid #3498db;
  border-radius: 6px;

  transition: color 250ms ease-in-out;

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    display: block;

    width: 0;
    height: 100%;

    background: #3498db;

    transition: width 250ms ease-in-out;
  }

  i {
    position: absolute;
    inset: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 20%;
    margin-right: 5px;

    font-size: 22px;

    border-right: 2px solid;
  }

  &:hover {
    color: #fff;
    background: transparent;
    outline: 0;

    &::after {
      width: 110%;
    }
  }

  &:focus {
    background: transparent;
    outline: 0;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
    filter: grayscale(100%);
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }
}

.dropContainer {
  position: absolute;
  z-index: 2;
  inset: 0;

  width: 100%;
  height: 100%;

  border-radius: 1rem;
}

.prompt {
  color: #3498db;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  padding-left: 10px;
  animation: offsetX 0.5s;
}
@keyframes offsetX {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.video {
  position: relative;
  background-color: #000;

  &__fileName {
    pointer-events: none;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    padding: 10px;

    color: #fff;

    background-image: linear-gradient(
      to bottom,
      rgb(0 0 0 / 100%),
      rgb(0 0 0 / 75%),
      rgb(0 0 0 / 50%),
      rgb(0 0 0 / 25%),
      rgb(0 0 0 / 0%)
    );
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    gap: 20px;

    padding: 10px;
  }

  &__iconsContainer {
    display: flex;
    gap: 5px;
  }
}
