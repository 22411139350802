.ba__title {
  margin: 1.33em 0;
  padding: 1em 0;
  font-weight: 400;
  border-top: 1px solid #ddd;
}

.ba__label {
  font-size: 0.85em;
  opacity: 0.75;
}

.ba__input {
  display: block;

  width: calc(100% - 15px);
  max-width: 200px;
  margin: 1em auto;
  padding: 0.3em;

  font-size: 1.2em;

  border: 1px solid #808080;
}
