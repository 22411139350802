.searchTabButtons {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.selected {
  color: white;
  opacity: 1;
  background-color: #08e !important;
  border-bottom: 2px solid transparent;
}

.affected {
  border-bottom: 2px solid white;
}