.listClassName {
    height: 400px;
    overflow-y: scroll;
    min-width: 400px;
    overflow-x: hidden;
}


.selectBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.labelSelect {
    font-weight: 500;
    color: #808080;
}

.selectEl {}