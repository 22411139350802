.banned-card {
  position: relative;

  min-height: 52px;
  margin: 10px 0;
  padding: 13px 10px 15px 64px;

  list-style-type: none;

  background-color: white;
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);

  transition: 0.3s;
}

.banned-card .dateTime {
  display: inline-block;
  margin-left: 2em;
  font-size: 0.8em;
  opacity: 0.75;
}

.icon {
  position: absolute;
  left: 12px;

  display: block;

  width: 36px;
  height: 36px;
  margin-top: -6px;

  opacity: 0.9;
  background: #f5f5f5 url('assets/images/socials/channel-none.png') no-repeat center;
  background-size: contain;
  border-radius: 24px;
}

.banned-card.chan-fb .icon {
  background-image: url('assets/images/socials/channel-fb.png');
}

.banned-card.chan-fbcomm .icon {
  background-image: url('assets/images/socials/channel-fbcomments.png');
}

.banned-card.chan-tlgr .icon {
  background-image: url('assets/images/socials/channel-telegram.png');
}

.banned-card.chan-vk .icon {
  background-image: url('assets/images/socials/channel-vk.png');
}

.banned-card.chan-fbinstagram .icon {
  background-image: url('assets/images/socials/channel-inst.png');
}

.banned-card.chan-vkcomm .icon {
  background-image: url('assets/images/socials/channel-vkcomments.png');
}

.banned-card.chan-sms .icon {
  background-image: url('assets/images/socials/channel-sms.png');
}

.banned-card.chan-vbr .icon {
  background-image: url('assets/images/socials/channel-viber.png');
}

.banned-card.chan-live .icon {
  background-image: url('assets/images/socials/channel-live.png');
}

.banned-card.chan-whatsapp .icon {
  background-image: url('assets/images/socials/channel-whatsapp.png');
}

.banned-card.chan-smsmoney .icon {
  background-image: url('assets/images/socials/channel-smsRub.png');
}

.deban {
  cursor: pointer;

  position: absolute;
  top: 13px;
  right: 10px;

  display: block;

  min-width: 24px;
  height: 24px;

  opacity: 0.75;
  background-color: #00d068;
  background-size: contain;
  border-radius: 3px;

  transition: opacity 0.25s ease-in-out;
}

.deban::after {
  content: 'РАЗБЛОКИРОВАТЬ';

  display: block;

  padding: 6px 10px;

  font-size: 9px;
  font-weight: bold;
  color: white;
}

.deban:hover {
  opacity: 1;
}
