.session-row {
  position: relative;

  display: flex;

  /* min-height: 86px; */
  padding: 10px 7px 10px 10px;

  color: #404040;
  /* 
  background-position-x: calc(100% - 7px);
  background-size: 24px; */
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.session-row:hover .btnPin {
  display: block;
}

.btnPin {
  display: none;
  position: absolute;
  top: 10px;
  right: 40px;
}

.btnPin.pinned {
  display: block;
}

.session-row.selected {
  background-color: rgb(100 180 255 / 15%);
}

.module-type {
  width: 60px;
  height: 64px;

  /* position: absolute; */
  margin-right: 10px;

  background: transparent url('assets/images/socials/channel-none.png') no-repeat center;
  background-size: contain;
}

.module-type.type-autoreply {
  background-image: url('assets/images/icons/module-autoreply@3x.png');
}

.module-type.type-vote {
  background-image: url('assets/images/icons/module-vote@3x.png');
}

.module-type.type-quiz {
  background-image: url('assets/images/icons/module-quiz@3x.png');
}

.module-type.type-broadcast {
  background-image: url('assets/images/icons/module-tv@3x.png');
}

.module-type.type-pulse {
  background-image: url('assets/images/icons/module-pulse.png');
}

.module-type.type-content {
  background-image: url('assets/images/icons/module-content@3x.png');
}

.module-type.type-registration {
  background-image: url('assets/images/icons/module-registration@3x.png');
}

.activity {
  position: absolute;
  top: 10px;
  left: 5px;

  display: block;

  width: 18px;
  height: 18px;

  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 12px;
}

.activity.active {
  background: red url('assets/images/icons/sess-active.png') no-repeat center;
  background-size: contain;
  border-color: white;
}

.activity.paused {
  background: red url('assets/images/icons/sess-paused.png') no-repeat center;
  background-size: contain;
  border-color: white;
}

.activity.created {
  background-color: #08e;
  border-color: white;
}

.date_time_item {
  position: absolute;
  right: 50px;
  bottom: 7px;

  padding-bottom: 3px;

  font-size: 0.9em;
  font-weight: normal;

  opacity: 0.75;
}

.key-tag {
  float: right;
  display: inline-block;
  display: none;

  margin-right: 10px;
  padding: 4px 7px;

  font-size: 0.85em;
  font-weight: bold;
  color: white;

  background-color: rgb(100 140 180 / 50%);
  border-radius: 5px;
}

.key-tag::before {
  content: '#';
  opacity: 0.5;
}

.title {
  clear: both;
  margin: 10px 0 5px;
  font-size: 15px;
}

.description {
  font-size: 0.85em;
  opacity: 0.75;
}

.memo {
  display: block;

  padding: 5px 0;

  font-size: 0.85em;
  font-style: italic;
  color: rgb(0 40 90 / 50%);
}

.lbSess__open-btn {
  cursor: pointer;
  align-self: center;
  width: 25px;
  margin-left: auto;
}

.lbSess__open-btn img {
  display: block;
  width: 100%;
  height: auto;
}

.start,
.finish {
  position: relative;

  display: block;

  padding-bottom: 3px;

  font-size: 0.9em;
  font-weight: normal;

  opacity: 0.75;
}

.votes {
  position: relative;

  display: block;

  padding-bottom: 3px;

  font-size: 0.9em;
  font-weight: normal;

  opacity: 0.75;
}

.msgsCount {
  display: block;
  font-weight: bold;
}

.click-area {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}