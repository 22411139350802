.statistics {
  display: flex;
  align-items: center;
}

.statistics > * + * {
  margin-left: 10px;
}

.statistics__group {
  display: flex;
  align-items: center;
}

.statistics__group > * + * {
  margin-left: 5px;
}

.icon {
  display: block;
  max-width: 16px;
  max-height: 16px;
}
