.message-action {
  float: right;
  overflow: hidden;
  display: flex;

  height: 26px;
  margin-top: -2px;
}

.message-action button + button {
  margin-left: 5px;
}
