.avatar {
  overflow: hidden;
  width: 80px;
  height: 80px;
}

.rounded {
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
