.msg-view-box {
  display: inline-table;
  width: 100%;
  max-width: 45%;
  margin-left: 10px;
}

.msg-actions-box {
  padding: 25px 10px 10px;
}

.msg-actions-box > * + * {
  margin-top: 10px;
}
