.container {
  background-color: #fff;
}

.body {
  overflow-y: auto;
  max-height: 400px;
}

.emptyMessage {
  padding: 10px 20px 20px;
  text-align: center;
}

.msg-list {
  padding: 0 10px 10px;
  transition: padding-top 0.25s ease-in-out;

  -webkit-overflow-scrolling: touch;
}

.info {
  position: sticky;
  z-index: 1;
  top: 5px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  min-width: 300px;
  margin: 8px 0 4px;
  padding: 4px 12px;

  font-size: 12px;

  background: #fff;
  border: 1px solid #eee;
  border-radius: 50px;
}

.statistics {
  display: flex;
  flex: 1;
}

.statistics_left {
  justify-content: flex-start;
}

.statistics_right {
  justify-content: flex-end;
}

.scroll-box {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;

  transition: padding-top 0.3s;

  /* height: 100vh; */
  -webkit-overflow-scrolling: touch;
}
