.toolbar {
  position: absolute;
  z-index: 100;

  overflow: hidden;

  /* display: flex; */
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  /* position: absolute; */
  height: 44px;
  margin-top: -40px;
  padding: 5px 25px 10px 5px;
  padding-right: 25px;

  line-height: 0;
  vertical-align: middle;

  background-color: white;
  border-bottom: 1px solid rgb(0 0 0 / 10%);

  transition: all 0.3s;
}

.toolbar.active {
  box-shadow: 0 20px 20px 9px rgb(0 0 0 / 10%);
}

.sessions-tbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin: 0;
  padding: 0;

  list-style: none;
}

.st__item.title {
  position: relative;

  padding: 0 10px;

  font-size: 16px;
  color: rgb(0 0 0 / 75%);

  opacity: 0.75;
}

.st__item.disabled {
  opacity: 0.25;
}

.st__item:hover:not(.disabled, .title) {
  cursor: pointer;
  opacity: 1;
}

.st__item.btn-reload {
  font-size: 16px;
}

.back-button,
.btn.btn-back {
  margin-right: 3px;
  margin-left: 3px;
  padding: 3px 10px;

  font-size: 26px;
  line-height: 0;
  color: #57adf2;
  text-align: center;

  background: #fff;

  transition: background-color 0.2s ease-in-out;
}

.back-button,
.title,
.module-type-name {
  cursor: default;
}

.back-button:hover,
.btn-back:hover {
  background: #efefef !important;
}

.btn {
  cursor: pointer;
  user-select: none;

  min-width: auto;
  height: auto;
  margin-right: 3px;
  margin-left: 3px;
  padding: 6px 8px;

  font-size: 20px;
  line-height: 0;
  color: #6f6f6f;
  color: #57adf2 !important;
  text-align: center;

  opacity: 0.75;
  border-radius: 3px;

  transition: color 0.2s ease-in-out;
}

.btn:hover {
  background: #efefef;
}

.module-tb-buttons .tb-button,
.ms__filter-control h4 {
  font-size: 15px;
  font-weight: 300;
  color: rgb(0 0 0 / 75%);
}

.ms__selected-interactives {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  padding: 14px 0;
}

.ms__selected-interactives li + li {
  margin-left: 25px;
}
