.file-upload__errors h3 {
  margin-top: 17px;
  margin-bottom: 25px;
  padding: 16px;

  color: #fff;

  background: #ff5252fa;
  border-radius: 5px;
}
