.scrollTopBtn {
    cursor: pointer;

    position: fixed;
    z-index: 2;
    right: 60px;
    bottom: 10px;
    transform: scale(0);

    display: none;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    opacity: 1;
    background: #f0f0f0;
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);

    animation: btnVisible 0.2s linear forwards;
}

@keyframes btnVisible {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.scrollTopBtn:hover {
    background: #f0f0f0;
}