.count {
  width: 152px;
  font-weight: bold;
  text-align: right;
}

/* .count:before {
    content: "Голосов: ";
    font-weight: normal;
    color: gray;
    font-size: 0.7em;
  } */

.count-title {
  font-size: 0.7em !important;
  font-weight: normal;
  color: gray;
  text-transform: capitalize;
}

.percent {
  display: block;
  font-size: 1.8em;
  font-weight: normal;
}

.percent::before {
  content: '% ';
  font-size: 0.7em;
  opacity: 0.5;
}
