.other-sett {
  position: relative;
}

.mvs__title {
  padding: 1em 0;
  padding-bottom: 0;
  font-weight: 400;
  border-top: 1px solid #ddd;
}

.mvs__desc {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgb(136 136 136);
}

.msg-view-box {
  display: inline-table;
  width: 100%;
  max-width: 45%;
  margin-left: 10px;
}

.avatarForm {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  align-items: center;
}

.avatarForm__checkboxes {
  display: flex;
  align-items: center;
  gap: 10px;
}