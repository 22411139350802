.count-notifications {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2.5px 4.5px;

  font-size: 9px;
  line-height: 1;
  color: #fff;

  background: #f86050;
  border-radius: 14px;
}
