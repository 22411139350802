.pictureBlock {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px dotted #999;
    margin-bottom: 1rem;
}

.dateLabel {
    font-size: 16px;
    color: #b3b3b3;
    margin-bottom: 0.5rem;
}

.tagsBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dotted #999;
    margin-bottom: 1rem;
}

.tagsTitle {
    margin-bottom: 1rem;
}

.tagsInputBlock {
    flex: 2;
}