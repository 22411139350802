@import './normalize.css';
@import '../fonts/lato/lato.css';
@import '../fonts/fontawesome-pro/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');


/* Класс закрепленного перетаскиваемого интерактива */
.selectedPin {
  background-color: rgb(100 180 255 / 15%)
}

body {
  position: fixed;

  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  font-family: Lato, 'San Francisco', 'Noto Color Emoji', 'Open Sans', 'Helvetica Neue',
    'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari',
    'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada',
    'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu',
    'Noto Sans Thai', sans-serif !important;

  font-size: 14px;
  font-weight: normal;

  background: url('assets/images/welcome-back.png') no-repeat;
  background-position-x: 120%;
  background-position-y: 15vh;
  background-size: 90%;
}

.video-react .video-react-big-play-button {
  background: transparent !important;
  border: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  user-select: none;
  box-sizing: border-box;
  margin: 0;
  border: 0;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.disabled {
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.unvisible {
  visibility: hidden !important;
}

.sel-off {
  user-select: none;
}

.select-text {
  user-select: text;
}

/* ___HELPERS___ */
.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.overflow-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.p-absolute {
  position: absolute !important;
}

.p-relative {
  position: relative !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* VERTICAL ALIGNMENT */
.align-middle {
  vertical-align: middle;
}

/* TEXT ALIGNMENT */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* HEIGHT */

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

/* FLEX HELPERS */

.flex-1 {
  flex: 1 1 0%;
}

.flex-100 {
  flex: 1 1 100%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

/* enabling flexbox */

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

/* flex direction */

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

/* flex wrap */

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* flex justify */

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

/* flex align */

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

/* flex align self */

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-auto {
  align-self: auto;
}

.align-self-stretch {
  align-self: stretch;
}

/* TYPOGRAPHY */

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* SPACING HELPERS */

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-2\.5 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0\.5 {
  margin-right: 2px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 2px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.-mt-5 {
  margin-top: -20px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.ma-0 {
  margin: 0 !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

/* PADDING */
.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-auto {
  padding-left: auto !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pa-0 {
  padding: 0 !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* FONT WEIGHT */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

/* OPACITY */
.opacity-0 {
  opacity: 0 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

/* WIDTH */

.min-w-unset {
  min-width: unset !important;
}

*::-webkit-scrollbar {
  width: 16px;
  background-color: #fff;
}

/* background of the scrollbar except button or resizer */

*::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border: 4px solid #fff;
  border-radius: 16px;
}

/* Collapsible */

.Collapsible {
  /* background-color: white; */
  border-bottom: 1px solid #ddd;
}

.Collapsible__contentInner {
  /* padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; */
}

.Collapsible__contentInner p {
  /* margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px; */
}

.Collapsible__contentInner p:last-child {
  /* margin-bottom: 0; */
}

.Collapsible__trigger {
  display: flex;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.Collapsible__trigger:hover {
  cursor: pointer;
  background: #f6f6f6;
}

.Collapsible__trigger-info {
  flex: 1;
}

.Collapsible__trigger-icon {
  align-self: center;

  margin-left: auto;

  font-size: 16px;
  color: #808080;

  transition: transform 300ms;
}

.Collapsible__trigger.is-open .Collapsible__trigger-icon {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  color: black;
  background-color: #cbb700;
}

/* loader */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: relative;
  width: 20px;
  margin: 0 auto !important;
  margin: 5px auto 0;
}

.loader .circular {
  position: absolute;
  inset: 0;
  transform-origin: center center;

  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;

  animation: rotate 2s linear infinite;
}

@keyframes widthAnimate {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.green {
  color: rgb(71 189 31);
}

.gray {
  color: rgb(125 125 125);
}

.content-container {
  display: flex;
  flex-direction: column;
}

.work-panel {
  position: absolute;
  top: 0;
  right: 0;

  width: calc(50% - 38px - 1px);
  height: 100%;

  /* $sizeListPane, $sizeLeftBar */

  background-color: rgb(255 255 255 / 50%);
  border-left: 1px solid rgb(0 0 0 / 20%);

  transition: none;
}