/* .chat-msg-check {
  position: absolute;
  bottom: 21px;
  right: 31px;
  font-size: 12px;
  font-weight: 600;
  color: #8a8d91;
  white-space: nowrap;
} */

.chat-msg-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.chat-msg-date {
  position: absolute;
  bottom: 5px;

  font-size: 12px;
  font-weight: 600;
  color: #8a8d91;
  white-space: nowrap;
}

.chat-msg {
  position: relative;
  display: flex;
  padding: 0 20px 20px;
}

.chat-msg-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 360px;
}

.chat-msg-content p {
  user-select: text;
}

.chat-msg-wrapper {
  padding: 3px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #2b2c33;
}

.chat-msg-wrapper a {
  font-size: 14px;
}

.chat-msg-attachments img {
  width: 100%;
  object-fit: cover;
}

.chat-msg-attachments>* {
  /* box-shadow: 0 0 15px #ccc; */
}

.chat-msg-attachments-with-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-msg-attachments {
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding: 5px;

  border-radius: 6px;
}

.chat-msg-attachments--channel {
  border-left: 3px solid;
}

.chat-msg-text {
  margin: 0;
  padding: 6px 7px 8px 9px;

  background-color: #f1f0f0;
  border-left: 3px solid;
  border-radius: 10px 10px 10px 0;
}

.chat-msg-text p {
  word-break: break-word;
}

.owner .chat-msg-text {
  padding: 6px 8px;
  background-color: transparent;
  border-left: none;
}

.chat-msg-text.with-attachments {
  margin: 0;
  margin-top: 4px;
  padding: 4px 4px 5px 6px;
}

.chat-msg-wrapper p.msg-img-desc {
  max-width: 300px;
  margin: 0;
  padding: 0 5px;
  word-break: break-word;
}

.chat-msg-wrapper .player {
  padding: 0 24px !important;
  background: transparent;
  box-shadow: none;
}

.chat-msg-wrapper .player .artist {
  display: none;
}

.chat-msg-wrapper .player .past_time {
  display: none;
}

.chat-msg-wrapper .player .rate_picker {
  display: none;
}

.chat-msg-wrapper .player .current_rate {
  display: none;
}

.chat-msg-wrapper .player .sub-controls {
  display: none;
}

.chat-msg-wrapper .player .progress {
  width: 75%;
}

.chat-msg-wrapper+.chat-msg-wrapper {
  margin-top: 10px;
}

.owner {
  flex-direction: row-reverse;
}

.owner .chat-msg-content {
  align-items: flex-end;
  margin-left: 0;
}

.owner .chat-msg-wrapper {
  color: #2b2c33;
  background-color: #f1f0f0;
  border-radius: 10px 10px 0;
}

.owner .chat-msg-date {
  left: auto;
}

.msg-img-wrapper {
  cursor: pointer;

  overflow: hidden;

  width: max-content;
  min-width: 150px;
  height: 200px;

  border-radius: 6px;
}

.msg-img-wrapper img {
  display: block;
  height: 100%;
  object-fit: cover;
}

.msg-video-wrapper {
  cursor: pointer;

  overflow: hidden;

  width: 352px;
  max-width: inherit;
  height: 200px;

  border-radius: 6px;
}

.msg-video-wrapper video {
  height: 100%;
}

.msg-audio-wrapper {
  max-width: 360px;
}

.chan-facebook {
  border-left-color: #4267b2;
}

.chan-facebookcomments {
  border-left-color: #4267b2;
}

.chan-telegram, .chan-tgcomm {
  border-left-color: #44a7d9;
}

.chan-telegrambot {
  border-left-color: #44a7d9;
}

.chan-vkgroup {
  border-left-color: #07f;
}

.chan-vkcomm {
  border-left-color: #07f;
}

.chan-sms {
  border-left-color: #19b89f;
}

.chan-ok,
.chan-okgroup {
  border-left-color: #ff8a19;
}

.chan-viber {
  border-left-color: #9661ab;
}

.chan-livechat {
  border-left-color: #94bd5c;
}

.chan-whatsapp {
  border-left-color: #19e25b;
}

.chan-yt_live {
  border-left-color: #db2833;
}

.chan-smsmoney {
  border-left-color: #19ba9f;
}

.chan-smsMoney {
  border-left-color: #19ba9f;
}

.chan-smsrub {
  border-left-color: #19ba9f;
}

.chan-smsRub {
  border-left-color: #19ba9f;
}

.chan-fbinstagram {
  /* border-left-color: linear-gradient(180deg, rgba(133,69,221,1) 0%, rgba(214,81,173,1) 23%, rgba(252,133,75,1) 67%, rgba(253,212,122,1) 100%); */
  border-left-color: rgb(214 81 173 / 100%);
}

.chan-alisa {
  border-left-color: #7148ff;
}