.dialog {
  position: relative;

  overflow-y: auto;
  display: flex;
  flex-direction: column;

  max-height: calc(100% - 64px);
  margin: 32px;

  color: rgb(0 0 0 / 87%);

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%),
    0 9px 46px 8px rgb(0 0 0 / 12%);

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
