.response {
  margin-bottom: 8px;
  padding-left: 7px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333;

  border-left: 2px solid;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.response--animation {
  transform-origin: 0 0;
  transform: scale(0);
  animation: bounce 500ms linear both;
}

@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.chan-facebook {
  border-left-color: #4267b2;
}

.chan-facebookcomments {
  border-left-color: #4267b2;
}

.chan-telegram {
  border-left-color: #44a7d9;
}

.chan-telegrambot {
  border-left-color: #44a7d9;
}

.chan-vkgroup {
  border-left-color: #07f;
}

.chan-vkcomm {
  border-left-color: #07f;
}

.chan-sms {
  border-left-color: #19b89f;
}

.chan-ok,
.chan-okgroup {
  border-left-color: #ff8a19;
}

.chan-viber {
  border-left-color: #9661ab;
}

.chan-livechat {
  border-left-color: #94bd5c;
}

.chan-whatsapp {
  border-left-color: #19e25b;
}

.chan-yt_live {
  border-left-color: #db2833;
}

.chan-smsmoney {
  border-left-color: #19ba9f;
}

.chan-smsMoney {
  border-left-color: #19ba9f;
}

.chan-smsrub {
  border-left-color: #19ba9f;
}

.chan-smsRub {
  border-left-color: #19ba9f;
}

.chan-fbinstagram {
  /* border-left-color: linear-gradient(180deg, rgba(133,69,221,1) 0%, rgba(214,81,173,1) 23%, rgba(252,133,75,1) 67%, rgba(253,212,122,1) 100%); */
  border-left-color: rgb(214 81 173 / 100%);
}

.chan-alisa {
  border-left-color: #7148ff;
}
