.refresh-qr-btn {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 200px;
  margin-right: 70px;

  color: #fff;

  background: #5fb9b0;
  border: none;
  border-radius: 50%;
  outline: none;

  transition: transform 0.3s;
}

.refresh-qr-btn:hover {
  background: #5fb9b0;
}

.refresh-qr-btn:active {
  transform: scale(0.9);
}
