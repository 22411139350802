.input-group {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;

  width: 100%;
}

.rss-connect-info {
  margin-top: 0.5rem;
  color: #524e4e;
  text-align: center;
  opacity: 0.33;
}
