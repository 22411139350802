.container {
  display: flex;
  justify-content: center;
  margin-right: -100px;
  padding: 1rem 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;

  padding: 10px;
}

.control-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.control-panel {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.textarea {
  margin: 0;
  border: 1px solid gray;
  border-radius: 0.2rem;
}

.body {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.preview {
  position: relative;

  overflow: hidden;

  width: 0.75rem * 30.92;
  max-width: 100%;
  min-height: 350px;
  height: 86.16vh;
  max-height: 0.75rem * 62.84;
  padding-top: 0.625rem * 2.3;
  padding-bottom: 10px;

  font-size: 62.5%;
  font-size: 0.625rem * 2;

  background: url('../../../../assets/images/whatsapp-bg.png') center center / cover no-repeat;
  border-radius: 0.75rem * 5;

  &__img {
    pointer-events: none;

    position: absolute;
    z-index: 2;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: fill;
  }

  &__inner {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  &__scrollBox {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex: 1;
    flex-direction: column;

    margin-right: -10px;
    padding: 2rem 0.5rem 0 2.25rem;
    padding-bottom: 16px;
  }

  &__controls {
    z-index: 1;

    display: flex;
    justify-content: center;
    padding: 10px 0 20px 0;
    background-color: #fff;
    box-shadow: 0 0 10px 0 #999;
    // todo: посмотреть позиционирование элементов вцелом
    width: 98.8%;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__field {
    background-color: #fff;
    border-radius: 5px;
  }

  &__textFieldContainer {
    position: relative;

    overflow: hidden;
    display: flex;
    flex-basis: 100%;
    order: -1;

    background-color: #fff;
    border-radius: 5px;

    & > * {
      flex: 1;
    }
  }
}

.addButton {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;

  width: 40px;
  height: 40px;

  background-color: #90da6b;
  border: 0;
  border-radius: 50%;

  transition: background-color 0.2s;
}

.delete {
  padding: 20px;

  font-size: 76px;
  color: #999;

  opacity: 0.2;

  transition: all 0.2s;

  &_isDragging {
    opacity: 1;

    &:hover {
      transform: scale(1.2);
      color: red;
    }
  }
}

.responses {
  position: relative;

  align-self: flex-start;

  max-width: 80%;
  margin: 10px 10px 0;

  background-color: #fefefe;
  border-radius: 10px;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: -12px;

    display: block;

    width: 32px;
    height: 32px;

    border: 16px solid transparent;
    border-top-color: #fefefe;
  }
}

.message {
  border: 1px solid transparent;
  transition: border-color 0.2s;

  &_onEdit {
    border-color: red;
  }
}
