.variant {
  position: relative;

  display: flex;

  min-height: 50px;
  margin-bottom: 1em;

  /* padding-top: 1em; */

  /* padding-left: calc(64px + 5px); */
  padding-bottom: 10px;
}

.varnum {
  /* position: absolute; */
  width: 64px;

  /* left: 0; */
  font-size: 3em;
  font-weight: bold;
  text-align: center;

  opacity: 0.33;
}
