.list-item {
  display: flex;
  margin: 0 -11px;
  padding: 8px 10px;
  color: #404040;
}

.list-item:hover {
  cursor: pointer;
  color: white;
  background-color: #08e;
}

.list-item.list-item--no-hover:hover {
  cursor: default;
  color: #404040;
  background-color: unset;
}

.selected {
  color: white;
  background-color: #08e !important;
}
