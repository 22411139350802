.profile-card__media-item {
  cursor: pointer;

  position: relative;

  overflow: hidden;

  width: 60px;
  height: 70px;

  color: #474747;

  background: #dcdcdc;
  border-radius: 10px;
}

.profile-card__media-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
