.modal {
  position: relative;
  z-index: 99999999999;
  width: 100vw;
  height: 100vh;
}

.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background: rgb(0 0 0 / 70%);
  backdrop-filter: blur(0);
}

.modal__content {
  position: fixed;
  inset: 0;

  overflow: hidden !important;
  display: flex;
  flex-direction: column;

  width: 732px;
  height: max-content;
  max-height: 80vh;
  margin: auto;
  padding: 0;

  background: #fff;
}

.modal__inner {
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: center;

  /* для картинок в новостях */
  align-items: center;
  flex-direction: column;

  padding: 30px;
}

.modal__inner>* {
  height: 100%;
  border: 1px solid gray;
}

.modal__content button {
  width: 135px;
}

.modal__content button:focus {
  color: transparent;
  background: transparent;
}

.modal__footer {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}