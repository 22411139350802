.main-chat-main {
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;

  padding-top: 20px;

  border-bottom: 2px solid #e5e5e5;
}

.main-chat-main-msg-list {
  position: relative;
  box-sizing: border-box;
  margin-top: 68px;
}

.main-chat-main-msg-list--with-media {
  margin-top: 167px;
}

.scroll-bottom-btn {
  cursor: pointer;

  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 5px;
  transform: scale(0);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  opacity: 1;
  background: #f0f0f0;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);

  transition: transform 0.3s;
}

.scroll-bottom-btn_notify {
  position: absolute;
  top: -5px;
  left: -5px;
  transform: scale(0);

  display: inline-block;

  min-width: 0.9em;
  min-height: 1em;
  padding: 0.3em 0.4em 0.4em;

  font-size: 12px;
  font-weight: 600;
  line-height: 1em;
  color: #fff;
  text-align: center;
  vertical-align: top;

  background-color: #919191;
  border-radius: 1.1em;
}

.scroll-bottom-btn_icon {
  color: #989898;
}

.scroll-bottom-btn_icon svg {
  pointer-events: none;

  position: absolute;
  top: 16px;
  left: 15px;

  display: block;
}
