#mouse-mover {
  cursor: col-resize;

  position: absolute;
  z-index: 10;
  top: 0;
  right: -3px;
  bottom: 0;

  width: 7px;
  height: 100vh;

  opacity: 0;
  background-color: #ccc;

  transition: none;
}
