.blur-wrapper {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;

  width: 100vw;
  width: 100%;
  height: 100vh;

  visibility: hidden;

  /* background-color: rgba(0, 0, 0, 0.05); */
  opacity: 0.1;
}

.blur-wrapper.shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.click-area {
  position: fixed;
  z-index: -1;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  background-color: rgb(0 0 0 / 0%);

  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
