.textAreaBlock {
    border: 0.5px solid #D0D0D0;
    border-radius: 2px;
    padding: 8px 10px;
    margin: 0;
}

.blockControls {
    display: flex;
    gap: 10px;
}