#left-toolbar {
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 76px;
  height: 100%;

  color: rgb(255 255 255 / 75%);
  text-align: center;

  background-color: #404040;
}

#left-toolbar > .under {
  margin-bottom: 7px;
}

.separator {
  margin: 10px;
  border-bottom: 2px solid rgb(255 255 255 / 10%);
}

#left-toolbar .separator {
  margin: 10px;
  border-bottom: 2px solid rgb(255 255 255 / 10%);
}

.toolbar-btn {
  width: 100% !important;
  font-size: 11px !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.main-btn {
  padding: 8px 0 12px !important;
}

.social-btn {
  padding: 12px 0 16px;
}
