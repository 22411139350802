.labels-item {
  cursor: pointer;
  margin: 0 -11px;
  padding: 8px 22px;
  color: #404040;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* marker */

.label {
  display: inline-block;
  margin: -3px 0 0;
  padding: 8px 8px 5px;
  border-radius: 3px;
}

.label:hover {
  cursor: pointer;
  color: white;
  background-color: #08e;
}

.label span {
  display: inline-block;

  width: 16px;
  height: 16px;

  border: 2px solid white;
  border-radius: 10px;
}

.label span.selected {
  border: 2px solid rgb(0 0 0 / 50%);
}

.label-red {
  background-color: #ff7373;
}

.label-orange {
  background-color: #ffa854;
}

.label-yellow {
  background-color: #fadd00;
}

.label-green {
  background-color: #5be954;
}

.label-blue {
  background-color: #3fbfff;
}

.label-purple {
  background-color: #ce89ff;
}

.label-gray {
  background-color: #aaa;
}
