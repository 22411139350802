span.rtdp {
  position: relative;

  display: inline-block;

  margin: 1px;
  padding: 2px;

  background-color: #fff;
  border: 1px solid #666;
  border-radius: 3px;
}

span.rtdp-inner {
  margin: 1px;
  padding: 1px;

  white-space: nowrap;

  background-color: #fff;
  border: none;
}

span.rtdp input {
  margin: 0;
  padding: 0;

  text-align: center;
  vertical-align: middle;

  background-color: #fff;
  border: none;
  outline: none;
}

span.rtdp input.rtdp-input {
  width: 31px;
}

span.rtdp input.rtdp-input.rtdp-year {
  width: 47px;
}

span.rtdp input.error {
  color: #000;
  background-color: #f99;
}

span.rtdp.readonly,
span.rtdp.readonly span.rtdp-inner,
span.rtdp.readonly input {
  color: #666;
  background-color: #f2f2f2;
}

span.rtdp input.rtdp-hint {
  color: #999;
}

span.rtdp span.rtdp-separator {
  color: #999;
}

span.rtdp span.rtdp-tooltip {
  z-index: 99;

  display: inline-block;

  margin: 0;
  padding: 1px 3px;

  font-family: sans-serif;
  font-size: 14px;
  line-height: 125%;

  background-color: #f5f5b5;
  border: 1px solid #666;
}

span.rtdp span.rtdp-errorbox {
  display: inline-block;

  margin: 0;
  padding: 1px 3px;

  font-family: sans-serif;
  font-size: 14px;
  line-height: 125%;
  white-space: nowrap;

  background-color: #f99;
  border: 1px solid #666;
}
