.workContent {
  position: relative;

  overflow-x: hidden;
  overflow-y: hidden;

  width: 100%;
  height: 100%;

  padding-top: 40px;

  background-color: rgb(255 255 255 / 50%);
}

.listbox {
  cursor: default;
  user-select: none;
  position: absolute;
  top: 44px;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: scroll;

  width: 100%;
  height: calc(100vh - 44px);

  background-color: white;

  -webkit-overflow-scrolling: touch;
}


.scrollTopBtn {
  cursor: pointer;

  position: fixed;
  z-index: 2;
  right: 60px;
  bottom: 10px;
  transform: scale(0);

  display: none;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  opacity: 1;
  background: #f0f0f0;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);

  animation: btnVisible 0.2s linear forwards;
}

@keyframes btnVisible {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.scrollTopBtn:hover {
  background: #f0f0f0;
}