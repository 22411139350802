.chart-stats-header {
  margin: 45px 0 5px;

  font-size: 20px;
  font-weight: 400;
  line-height: 0;
  text-align: center;
}

.chart-header-text {
  display: inline;
}

.saving {
  font-size: 20px;
}

.saving span.dot {
  font-size: 20px;

  animation-name: blink;
  animation-duration: 1.4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.saving span.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.saving span.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
