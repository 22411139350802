.dateTag {
  cursor: pointer;

  padding: 2px 7px;

  font-size: 12px;
  color: rgb(125 125 125);

  background-color: transparent;
  border-radius: 10px;

  transition: color 0.2s;
}

.dateTag--new {
  font-weight: bold;

  /* color: rgb(150, 27, 199); */
  color: rgb(71 189 31);
}

.dateTag--day {
  color: rgb(71 189 31);
}

.dateTag--week {
  color: rgb(122 193 35);
}

.dateTag--halfAMonth {
  color: rgb(179 184 43);
}

.dateTag--month {
  color: rgb(184 161 43);
}

.dateTag--1-3month {
  color: rgb(184 147 43);
}

.dateTag--3-6months {
  color: rgb(184 116 43);
}

.dateTag--6-12months {
  color: rgb(184 78 43);
}

.dateTag--yearAndMore {
  color: rgb(184 43 43);
}
