.msg-list {
  padding: 0 10px 10px;
  transition: padding-top 0.25s ease-in-out;

  -webkit-overflow-scrolling: touch;
}

.scroll-box {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;

  transition: padding-top 0.3s;

  /* height: 100vh; */
  -webkit-overflow-scrolling: touch;
}

#list-pane {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.items {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}
