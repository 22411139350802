/* RoundInput */

.round-input {
  width: 100%;
}

.round-input label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.round-input input,
.round-input textarea {
  box-sizing: border-box;
  width: 100%;
  max-width: unset;

  line-height: 17px;
  color: #404040;

  border: 1px solid #cdcdcd;
  border-radius: 4px;
  outline: none;
}

.round-input--small input,
.round-input--small textarea {
  padding: 6px;
  font-size: 12px;
}

.round-input--medium input,
.round-input--medium textarea {
  padding: 10px;
  font-size: 14px;
}

.round-large--small input,
.round-large--small textarea {
  padding: 15px;
  font-size: 16px;
}

.round-input--focusable input:focus,
.round-input--focusable textarea:focus {
  /* box-shadow: 0px 0px 0px 2px #0088ee;
    border: 1px solid transparent; */
  border: 1px solid #08e;
}

/* Chrome, Safari, Edge, Opera */

.round-input input::-webkit-outer-spin-button,
.round-input input::-webkit-inner-spin-button,
.hide-number-controls input::-webkit-outer-spin-button,
.hide-number-controls input::-webkit-inner-spin-button,
.round-input textarea::-webkit-outer-spin-button,
.round-input textarea::-webkit-inner-spin-button,
.hide-number-controls textarea::-webkit-outer-spin-button,
.hide-number-controls textarea::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */

.round-input input[type='number'],
.hide-number-controls input[type='number'],
.round-input textarea[type='number'],
.hide-number-controls textarea[type='number'] {
  appearance: textfield;
}

.round-input.has-error input,
.round-input.has-error textarea {
  border-color: #f30000 !important;
}

.round-input__error {
  min-width: 12px;
  margin: 0;
  margin-bottom: 5px;

  font-size: 10px;
  color: #f30000;

  opacity: 0;
}

.round-input.has-error .round-input__error {
  opacity: 1;
}

.disabled {
  opacity: 0.5;
}

.emojiContainer {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 5px;
}

.emojiContainer_multiline {
  margin: 16px 6px;
}

.inputWrapper {
  width: 100%;
}