.msg-view-box {
  display: inline-table;
  width: 100%;
  max-width: 45%;
  margin-left: 10px;
}

.titre-symbols {
  display: inline-flex;
  justify-content: space-between;

  min-width: 100%;
  max-width: 450px;
  margin-top: 20px;
  margin-bottom: 0;

  border-bottom: 1px dashed #d6d6d6;
}

.ts__input {
  width: 190px;
  max-width: 213px;
  margin: 0;
}

.ts__input input {
  padding: 0.3em;

  font-size: 1em;
  text-align: right;

  background: transparent;
  border: none;
}
