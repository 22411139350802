.volume_control {
  position: absolute;
  top: 50%;
  transform: translate(5%, -70%);

  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 0;
  height: 21px;
  padding-right: 36px;
  padding-left: 10px;

  visibility: hidden;
  opacity: 0;
  background: #505050;
  border-radius: 12px;

  transition: all 0.5s;
}

.volume_control.active {
  width: 130px;
  visibility: visible;
  opacity: 1;
}

.volume_progress_bar {
  position: relative;
  flex: 1;
  height: 2px;
  background: rgb(255 255 255 / 95%);
}

.volume_progress_control {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  top: -3.5px !important;
  left: 100%;

  box-sizing: border-box;
  width: 9px;
  height: 9px;
  padding: 5px;

  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px rgb(0 0 0 / 15%), 0 2px 4px rgb(0 0 0 / 15%);
}

.volume_progress_line {
  cursor: pointer;

  position: absolute;
  top: 0 !important;

  overflow: hidden;

  width: 100%;
  height: 2px;

  background: linear-gradient(to right, rgb(80 170 238), rgb(0 136 238));
  border-radius: 0.25rem;
}

.volume_progress_line:focus,
.volume_progress_control:focus,
.volume_progress_line:hover,
.volume_progress_control:hover {
  background: rgb(0 136 238);
}
