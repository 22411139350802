.tag {
  cursor: text;

  display: flex;
  align-items: center;

  margin: 2px 5px;
  padding: 5px;

  font-size: 14px;

  background: #eee;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.delete-tag {
  cursor: pointer;
  margin-bottom: -1px;
  margin-left: 10px;
  color: rgb(0 0 0 / 75%);
}
