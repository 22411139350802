.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 20px;

  background: white;
  border-radius: 0.2rem;
  box-shadow: 1px 1px 15px rgb(0 0 0 / 60%);
}

.checkBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
