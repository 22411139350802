.list-item-text {
  overflow: hidden;
  align-self: center;
  text-overflow: ellipsis;
  white-space: pre;
}

.list-item-text__secondary {
  margin-top: 3px;

  /* font-size: 0.875rem; */
  font-size: 12px;
  color: rgb(0 0 0 / 54%);
  white-space: normal;
}

.list-item-text--selected .list-item-text__secondary,
.list-item-text--hover .list-item-text__secondary {
  color: #fff;
  opacity: 0.75;
}
