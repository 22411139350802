.main-chat-header-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* width: 360px; */
  margin-left: auto;
}

.chat_block-btn {
  cursor: pointer;

  align-self: center;

  padding: 7px;

  font-size: 20px;
  color: #e02e2e;

  border-radius: 50%;
}

.chat_block-btn.unblock {
  color: #2e7d32;
}
